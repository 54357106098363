import Card from "components/Layout/Card";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "utils/helpers";

const MentorshipStatisticsChart = () => {
  const navigate = useNavigate();
  const {
    mentor_approvals_panding,
    recently_created_sessions,
    session_approvals_panding,
    total_mentor,
  } = getDataFromLocalStorage();
  return (
    <div className="cmt-20">
      <Card className="cpt-53 cpb-53 cps-53 cpe-53">
        <div className="row">
          <div className="col-md-6">
            <div className="text-20-500-25 color-1717 cmb-38">
              Mentorship Statistics
            </div>
            <div className="d-flex align-items-center gap-3 cmb-23">
              <div className="rounded-color bg-5110"></div>
              <div className="text-16-400-20 color-0000 min-width-250">
                Mentor Approvals Pending
              </div>
              <div className="text-16-400-20 color-0000">
                : {mentor_approvals_panding || 0}
              </div>
              <div className="color-7cff text-14-400-18 pointer"
              onClick={()=>{
                navigate(`/admin/mentorship-management/approval`);
              }}
              >
                View Details
              </div>
            </div>

            <div className="d-flex align-items-center gap-3 cmb-23">
              <div className="rounded-color bg-C991"></div>
              <div className="text-16-400-20 color-0000 min-width-250">
                Recently Created Sessions
              </div>
              <div className="text-16-400-20 color-0000">
                : {recently_created_sessions || 0}
              </div>
              <div className="color-7cff text-14-400-18 pointer"
              onClick={()=>{
                navigate(`/admin/mentorship-management/approval`);
              }}>
                View Details
              </div>
            </div>

            <div className="d-flex align-items-center gap-3 cmb-24">
              <div className="rounded-color bg-a35b"></div>
              <div className="text-16-400-20 color-0000 min-width-250">
                Session Approvals Pending
              </div>
              <div className="text-16-400-20 color-0000">
                : {session_approvals_panding || 0}
              </div>
              <div className="color-7cff text-14-400-18 pointer"
              onClick={()=>{
                navigate(`/admin/mentorship-management/approval`);
              }}>
                View Details
              </div>
            </div>

            <div className="d-flex align-items-center gap-3 text-20-500-25 color-1717 cps-38">
              <div className="">Total New Mentors</div>
              <div className="">: {total_mentor || 0}</div>
            </div>
          </div>
          <div className="col-md-6 h-100">
            <div
              className="flex justify-center items-center"
              style={{
                height: "500px", // Increase container height
                width: "100%", // Increase container width
                overflow: "visible", // Allow labels to render outside the chart
              }}
            >
              <ReactApexChart
                options={{
                  labels: [
                    "Mentor Approvals Pending",
                    "Recently Created Sessions",
                    "Session Approvals Pending",
                  ],
                  dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                      // Combine count and percentage
                      const count = opts.w.globals.series[opts.seriesIndex];
                      return `${count}\n(${val.toFixed(1)}%)`; // Count and percentage on separate lines
                    },
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      colors: ["#000000"], // Data label color
                    },
                    dropShadow: {
                      enabled: false, // Disable shadow for cleaner text
                    },
                  },
                  plotOptions: {
                    pie: {
                      customScale: 0.9,
                      dataLabels: {
                        offset: 70, // Move data labels outside the chart slice
                        minAngleToShowLabel: 15, // Ensures labels are displayed for smaller slices
                      },
                      expandOnClick: true,
                      donut: {
                        size: "50%",
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            showAlways: true,
                            label: "Total",
                            fontSize: "20px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "#171717",
                            offsetY: 20,
                            formatter: function (w) {
                              // Calculate and display the total count
                              return w.globals.seriesTotals.reduce(
                                (a, b) => a + b,
                                0
                              );
                            },
                          },
                        },
                      },
                    },
                  },
                  grid: {
                    padding: {
                      top: 10, // Adjust top padding for more plot area
                      bottom: 10, // Adjust bottom padding
                    },
                  },
                  colors: ["#E75110", "#C99100", "#2BA35B"],
                  stroke: {
                    width: 0, // Remove borders for a cleaner look
                  },
                  legend: { show: false }, // Hide legend
                }}
                series={[
                  mentor_approvals_panding || 0,
                  recently_created_sessions || 0,
                  session_approvals_panding || 0,
                ]}
                type="donut"
                height={350}
              />
            </div>
          </div>

          {/* <div className="col-md-6">
            <ReactApexChart
              options={{
                labels: [
                  "Mentor Approvals Pending",
                  "Recently Created Sessions",
                  "Session Approvals Pending",
                ],
                dataLabels: {
                  enabled: true,
                  formatter: function (val) {
                    return val.toFixed(2) + "%";
                  },
                  style: {
                    fontSize: "12px",
                    colors: ["#000000"], // Color for the data labels
                  },
                //   pie: {
                //     offset: 20, // Adjust the position of the labels outside
                //     dataLabels: {
                //       offset: -50, // Additional offset to move labels further outside
                //     },
                //   },
                },

                plotOptions: {
                  pie: {
                    expandOnClick: true,
                    donut: {
                      size: "50%",
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          showAlways: true,
                          label: "Total",
                          fontSize: "20px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "#171717",
                          offsetY: 20,
                          formatter: function (w) {
                            return total_mentor;
                          },
                        },
                      },
                    },
                  },
                },
                colors: ["#E75110", "#C99100", "#2BA35B"],

                stroke: {
                  curve: "smooth",
                  width: 3,
                },
                xaxis: {
                  categories: [
                    "Mentor Approvals Pending",
                    "Recently Created Sessions",
                    "Session Approvals Pending",
                  ],
                },

                legend: { show: false },
              }}
              series={[
                mentor_approvals_panding || 0,
                recently_created_sessions || 0,
                session_approvals_panding || 0,
              ]}
              type="donut"
              height={320}
            />
          </div> */}
        </div>
      </Card>
    </div>
  );
};

export default MentorshipStatisticsChart;
