import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import TextInput from "components/form/TextInput";
import { Table } from "react-bootstrap";
import { cloneDeep, filter, forEach, isEqual, map, unionBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAddOnPricing,
  // deleteEventPricing,
  fetchEventPriceCategories,
  // deleteAddOnPricing,
  // deleteEventPricing,
  // fetchEventPriceCategories,
  // fetchMemberships,
  // fetchUserEventDetails,
  // getEvent,
  getEventRegistrationDetails,
  manageEventRegistrationRequest,
  throwError,
  throwSuccess,
  updatePricingCategory,
} from "store/slices";
import { icons } from "utils/constants";
import {
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
  trimAllSpace,
  trimLeftSpace,
  // titleCaseString,
  // trimAllSpace,
  // trimLeftSpace,
} from "utils/helpers";
// import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
// import Dropdown from "components/form/Dropdown";
import Card from "components/Layout/Card";
import TextArea from "components/form/TextArea";
import "./NewRegistrationEventForm.scss";
import CreatablePricingDropdown from "components/form/CreatablePricingDropdown";
// import CreatablePricingDropdown from "components/form/CreatablePricingDropdown";

const ViewNewRegistrationEventForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { eventId, requestType, requestId } = params || {};
  const userData = getDataFromLocalStorage();
  const isEditable = userData?.user_type === "0";
  const isResourceView =
    userData?.user_type === "6" && eventId && requestType === "raised";
  const isAdminView =
    userData?.user_type === "0" && eventId && requestType === "pending";
  const { allMembershipList, send_to } = useSelector((state) => ({
    allMembershipList: state.global.membershipList,
    send_to: state.global.eventData.send_to || "",
  }));

  const membershipList = filter(allMembershipList, (e) =>
    send_to?.split(",").includes(e.id)
  );

  const [isReject, setIsReject] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCateLoading, setIsCateLoading] = useState(false);
  const [pricingCategoriesList, setPricingCategoriesList] = useState([]);
  const [registrationCategoriesList, setRegistrationCategoriesList] = useState(
    []
  );
  const [formValues, setFormValues] = useState({
    comment: "",
    reject_remarks: "",
  });
  const [category, setCategory] = useState([]);
  const [categoryBtnLoading, setCategoryBtnLoading] = useState(false);
  const [deleteLoading, setDeleteBtnLoading] = useState(false);
  // const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isBtnTypeLoading, setIsBtnTypeLoading] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [originalResponse, setOriginalResponse] = useState({});

  const [data, setData] = useState({
    pricing_categories: [
      {
        id: "",
        category: "",
      },
    ],
    event_pricing: [],
    add_on_pricing: [],
    dashboard_pricing_selection: [],
  });

  const updateCategory = async () => {
    setCategoryBtnLoading(true);
    let payload = data.pricing_categories?.map((elm) => {
      return { ...elm, event_id: eventId };
    });
    const resposne = await dispatch(
      updatePricingCategory({
        pricing_categories: payload,
        event_id: eventId,
        type: "update_request",
        requested_user_id: originalResponse?.requested_user_id,
      })
    );
    if (resposne.status === 200) {
      localStorage.removeItem("categories");
      fetchReviewrDetails();
      getEventPriceCategories();
    }
    setCategoryBtnLoading(false);
  };

  const addCategory = (type) => {
    let oldData = cloneDeep(data);
    if (type === "pricing_categories") {
      oldData.pricing_categories = [
        ...oldData.pricing_categories,
        { id: "", category: "" },
      ];
    } else if (type === "event_pricing") {
      const categories = map(category, (el) => {
        return { ...el, oral: "", poster: "" };
      });

      oldData.event_pricing = [
        ...oldData.event_pricing,
        {
          id: "",
          physical: cloneDeep(categories),
          virtual: cloneDeep(categories),
        },
      ];
    } else if (type === "add_on_pricing") {
      oldData.add_on_pricing = [
        ...oldData.add_on_pricing,
        { id: "", category: "", premium_members: "", non_premium_members: "" },
      ];
    } else {
      // Nothing
    }
    setData(oldData);
  };

  const deleteCategory = async (type, index, id) => {
    let oldData = cloneDeep(data);
    setDeleteBtnLoading(`${type}_${index}`);
    if (type === "pricing_categories") {
      oldData.pricing_categories.splice(index, 1);
    } else if (type === "event_pricing") {
      if (id) {
        // const resDelete = await dispatch(deleteEventPricing(id));
        // if (resDelete.status === 200) {
        oldData.event_pricing.splice(index, 1);
        // }
      } else {
        oldData.event_pricing.splice(index, 1);
      }
    } else if (type === "add_on_pricing") {
      if (id) {
        const resDelete = await dispatch(deleteAddOnPricing(id));
        if (resDelete.status === 200) {
          oldData.add_on_pricing.splice(index, 1);
        }
      } else {
        oldData.add_on_pricing.splice(index, 1);
      }
    } else {
      // Nothing
    }
    setDeleteBtnLoading("");
    setData(oldData);
  };

  const handelChangeInput = (e) => {
    const id = e.target.id;
    const sID = cloneDeep(id.split("-"));
    let value = trimLeftSpace(titleCaseString(e.target.value));

    let oldData = cloneDeep(data);
    if (sID[0] === "pricing_categories") {
      oldData.pricing_categories[sID[1]].category = value;
    } else if (sID[0] === "event_pricing") {
      if (sID.length > 2) {
        oldData.event_pricing[sID[1]][sID[2]][sID[3]][sID[4]] = trimAllSpace(
          value
        ).replace(/\D/g, "");
      } else {
        oldData.event_pricing[sID[1]].category = value;
      }
    } else if (sID[0] === "add_on_pricing") {
      if (sID[2] !== "category") {
        value = trimAllSpace(value.replace(/\D/g, ""));
      }
      oldData.add_on_pricing[sID[1]][sID[2]] = value;
    } else {
      // Nothing
    }

    setData(oldData);
  };

  //   const updatePrice = async (data) => {
  //     setIsBtnLoading(true);
  //     const isAddOnBtn = data.add_on_pricing.every(
  //       (o) => o.category && o.premium_members && o.non_premium_members
  //     );
  //     if (!isAddOnBtn) {
  //       data = { ...data, add_on_pricing: [] };
  //     }
  //     const payload = { ...data, event_id: eventId };
  //     const response = await dispatch(updateEventPricing(payload));
  //     if (response?.status === 200) {
  //       getEventPriceCategories();

  //       //   if (fetchEventDetails) {
  //       //     fetchEventDetails();
  //       //   }
  //       const text = oldData?.event_pricing?.[0]?.category ? "update" : "add";
  //       dispatch(showSuccess(`Registration price ${text} successfully.`));
  //     }
  //     setIsBtnLoading(false);
  //   };

  const handleChanged = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleRequest = async (data) => {
    console.log("data", data);
    setIsBtnTypeLoading(data?.action_type);
    const response = await dispatch(
      manageEventRegistrationRequest(objectToFormData(data))
    );
    if (response?.status === 200) {
      setFormValues({
        comment: "",
        reject_remarks: "",
      });
      dispatch(throwSuccess(response?.message));
      fetchReviewrDetails();
      setIsBtnTypeLoading(false);
    } else {
      dispatch(throwError({ message: response?.message }));
      setIsBtnTypeLoading(false);
    }
    setIsBtnTypeLoading(false);
  };

  // const getData = (resData) => {
  //   const {
  //     pricing_categories,
  //     event_pricing: eventPricing,
  //     add_on_pricing,
  //     dashboard_pricing_selection,
  //     admin_status,
  //   } = resData;

  //   // Ensure physical and virtual arrays exist for each event pricing entry
  //   let event_pricing = eventPricing?.map((el) => {
  //     return {
  //       ...el,
  //       physical: el?.physical || [],
  //       virtual: el?.virtual || [],
  //     };
  //   }) || [];

  //   // Add placeholders for missing categories with empty oral and poster fields
  //   const categories = pricing_categories.map((el) => {
  //     return { ...el, oral: "", poster: "" };
  //   });

  //   const dataWithOralPosterPrice = [
  //     {
  //       id: "",
  //       category: "",
  //       physical: cloneDeep(categories),
  //       virtual: cloneDeep(categories),
  //     },
  //   ];

  //   // Initialize setList with event_pricing or a placeholder
  //   let setList = event_pricing.length > 0 ? event_pricing : dataWithOralPosterPrice;

  //   // Handle mismatched categories in event_pricing
  //   if (event_pricing.length > 0) {
  //     const existingCategories = event_pricing[0]?.physical.map((item) => item.category);
  //     const missingCategories = categories.filter(
  //       (cat) => !existingCategories.includes(cat.category)
  //     );

  //     if (missingCategories.length > 0) {
  //       setList = setList.map((elm) => {
  //         return {
  //           ...elm,
  //           physical: cloneDeep([...elm.physical, ...missingCategories]),
  //           virtual: cloneDeep([...elm.virtual, ...missingCategories]),
  //         };
  //       });
  //     }
  //   }

  //   // Remove duplicates from physical and virtual arrays
  //   setList = setList.map((elm) => {
  //     const uniquePhysical = unionBy(elm.physical, "category");
  //     const uniqueVirtual = unionBy(elm.virtual, "category");

  //     return {
  //       ...elm,
  //       physical: uniquePhysical,
  //       virtual: uniqueVirtual,
  //     };
  //   });

  //   // Construct the new data object
  //   const newData = {
  //     ...resData,
  //     pricing_categories:
  //       pricing_categories?.length > 0
  //         ? pricing_categories
  //         : [{ id: "", category: "" }],
  //     event_pricing: setList,
  //     add_on_pricing:
  //       add_on_pricing?.length > 0
  //         ? add_on_pricing
  //         : [
  //             {
  //               id: "",
  //               category: "",
  //               premium_members: "",
  //               non_premium_members: "",
  //             },
  //           ],
  //     dashboard_pricing_selection: dashboard_pricing_selection,
  //     admin_status: admin_status,
  //   };

  //   console.log("newdata", newData);

  //   setData(newData);
  //   setOldData(newData);
  //   setIsLoading(false);
  // };

  // const getData = (resData) => {
  //   console.log("resData",resData);

  //   const {
  //     pricing_categories,
  //     event_pricing: eventPricing,
  //     add_on_pricing,
  //     dashboard_pricing_selection,
  //     admin_status,
  //   } = resData;
  //   let event_pricing = eventPricing?.map((el) => {
  //     return {
  //       ...el,
  //       physical: el?.physical || [],
  //       virtual: el?.virtual || [],
  //     };
  //   });
  //   console.log("event_pricing",event_pricing);

  //   const categories = map(pricing_categories, (el) => {
  //     return { ...el, oral: "", poster: "" };
  //   });

  //   console.log("categories",categories);

  //   const memberList = map(membershipList, (elem) => {
  //     const findData =
  //       dashboard_pricing_selection.find((o) => isEqual(o.id + "", elem.id))
  //         ?.data || [];

  //     const newfindData = unionBy(
  //       findData,
  //       pricing_categories.map((el) => ({ ...el, is_checked: false })),
  //       "id"
  //     );

  //     return {
  //       ...elem,
  //       data:
  //         newfindData.length > 0
  //           ? newfindData
  //           : map(pricing_categories, (el) => {
  //               return { ...el, is_checked: false };
  //             }),
  //     };
  //   });

  //   const dataWithOralPosterPrice = [
  //     {
  //       id: "",
  //       category: "",
  //       physical: cloneDeep(categories),
  //       virtual: cloneDeep(categories),
  //     },
  //   ];

  //   console.log("dataWithOralPosterPrice",dataWithOralPosterPrice);

  //   let setList =
  //     event_pricing.length > 0 ? event_pricing : dataWithOralPosterPrice;

  //   // new
  //   if (event_pricing.length > 0) {
  //     const existingCategories = event_pricing[0]?.physical.map(
  //       (item) => item.category
  //     );
  //     const missingCategories = categories.filter(
  //       (cat) => !existingCategories.includes(cat.category)
  //     );

  //     if (missingCategories.length > 0) {
  //       setList = setList.map((elm) => {
  //         return {
  //           ...elm,
  //           physical: cloneDeep([...elm.physical, ...missingCategories]),
  //           virtual: cloneDeep([...elm.virtual, ...missingCategories]),
  //         };
  //       });
  //     }
  //   }
  //   setList = setList.map((elm) => {
  //     const uniquePhysical = unionBy(elm.physical, "category")?.filter(
  //       (o) => o?.id
  //     );
  //     const uniqueVirtual = unionBy(elm.virtual, "category")?.filter(
  //       (o) => o?.id
  //     );

  //     return {
  //       ...elm,
  //       physical: uniquePhysical,
  //       virtual: uniqueVirtual,
  //     };
  //   });

  //   const newData = {
  //     ...data,
  //     pricing_categories:
  //       pricing_categories?.length > 0
  //         ? pricing_categories
  //         : [{ id: "", category: "" }],
  //     event_pricing: setList,
  //     add_on_pricing:
  //       add_on_pricing.length > 0
  //         ? add_on_pricing
  //         : [
  //             {
  //               id: "",
  //               category: "",
  //               premium_members: "",
  //               non_premium_members: "",
  //             },
  //           ],
  //     dashboard_pricing_selection: memberList,
  //     admin_status: admin_status,
  //   };
  //   setData(newData);
  //   setOldData(newData);
  //   setIsLoading(false);
  // };

  const getData = (resData) => {
    const {
      pricing_categories,
      event_pricing: eventPricing,
      add_on_pricing,
      dashboard_pricing_selection,
      admin_status,
    } = resData;

    console.log("resData", resData);

    // Ensure event pricing structure is normalized
    let event_pricing = eventPricing?.map((el) => ({
      ...el,
      physical: el?.physical || [],
      virtual: el?.virtual || [],
    }));

    const categories = map(pricing_categories, (el) => ({
      ...el,
      oral: "",
      poster: "",
    }));

    console.log("eventPricing", eventPricing);

    console.log("categoreies", categories);

    const memberList = map(membershipList, (elem) => {
      const findData =
        dashboard_pricing_selection.find((o) => isEqual(o.id + "", elem.id))
          ?.data || [];

      const newfindData = unionBy(
        findData,
        pricing_categories.map((el) => ({ ...el, is_checked: false })),
        "id"
      );

      return {
        ...elem,
        data:
          newfindData.length > 0
            ? newfindData
            : map(pricing_categories, (el) => ({
                ...el,
                is_checked: false,
              })),
      };
    });

    const dataWithOralPosterPrice = [
      {
        id: "",
        category: "",
        physical: cloneDeep(categories),
        virtual: cloneDeep(categories),
      },
    ];

    // console.log("dataWithOralPosterPrice",dataWithOralPosterPrice)

    // Manage event_pricing
    let setList =
      event_pricing?.length > 0 ? event_pricing : dataWithOralPosterPrice;
    console.log("setList", setList);

    // test
    // Only modify setList in edit mode
    if (isEditable && event_pricing?.length > 0) {
      // Map existing categories by id, category (name), oral, and poster

      const existingCategories = event_pricing[0]?.physical?.map((item) => ({
        id: item?.id,
        category: item?.category,
        oral: item?.oral || "", // Default to an empty string if missing
        poster: item?.poster || "", // Default to an empty string if missing
      }));

      console.log("existingCategories", existingCategories);

      // Find categories with matching ids and replace their category, oral, and poster
      const updatedCategories = existingCategories?.map((existing) => {
        console.log("existing", existing);

        const replacement = categories?.find((cat) => cat?.id === existing?.id);
        console.log("replacement:", replacement);
        return replacement
          ? {
              ...existing,
              category: replacement?.category,
              oral: replacement?.oral || existing?.oral,
              poster: replacement?.poster || existing?.poster,
            } // Replace fields if a match is found
          : existing; // Keep existing entry if no match
      });
      console.log("updatedCategories", updatedCategories);

      // Filter categories that are completely missing by id
      const missingCategories = categories
        .filter(
          (cat) =>
            !updatedCategories?.some((existing) => existing?.id === cat?.id)
        )
        .map((cat) => ({
          ...cat,
          oral: cat?.oral || "", // Add default values for oral and poster
          poster: cat?.poster || "",
        }));

      console.log("missingCategories", missingCategories);

      // Combine updated and missing categories
      const combinedCategories = [...updatedCategories, ...missingCategories];

      console.log("combinedCategories", combinedCategories);
      console.log("setList", setList);

      // Update setList for physical and virtual categories
      // setList = setList?.map((elm) => ({

      //   ...elm,
      //   physical: cloneDeep(combinedCategories),
      //   virtual: cloneDeep(combinedCategories),
      // }));

      setList = setList?.map((elm) => ({
        ...elm,
        physical: cloneDeep([
          ...elm?.physical?.map((item) => {
            const match = combinedCategories.find((cat) => cat.id === item.id);
            return match ? { ...item, category: match.category } : item;
          }),
          ...missingCategories.filter(
            (cat) => !elm?.physical?.some((item) => item?.id === cat?.id)
          ),
        ]),
        virtual: cloneDeep([
          ...elm?.virtual?.map((item) => {
            const match = combinedCategories.find((cat) => cat.id === item.id);
            return match ? { ...item, category: match.category } : item;
          }),
          ...missingCategories.filter(
            (cat) => !elm?.virtual?.some((item) => item?.id === cat?.id)
          ),
        ]),
      }));
    }

    if (!isEditable && event_pricing?.length > 0) {
      // Map existing categories by id, category (name), oral, and poster
      const existingCategories = event_pricing[0]?.physical?.map((item) => ({
        id: item?.id,
        category: item?.category,
        oral: item?.oral || "", // Default to an empty string if missing
        poster: item?.poster || "", // Default to an empty string if missing
      }));
      console.log("existingCategories", existingCategories);

      // Find categories with matching ids and replace their category, oral, and poster
      const updatedCategories = existingCategories?.map((existing) => {
        const replacement = categories?.find((cat) => cat?.id === existing?.id);
        return replacement
          ? {
              ...existing,
              category: replacement?.category,
              oral: replacement?.oral || existing?.oral,
              poster: replacement?.poster || existing?.poster,
            } // Replace fields if a match is found
          : existing; // Keep existing entry if no match
      });

      console.log("updatedCategories", updatedCategories);

      // Filter categories that are completely missing by id
      const missingCategories = categories
        .filter(
          (cat) =>
            !updatedCategories?.some((existing) => existing?.id === cat?.id)
        )
        .map((cat) => ({
          ...cat,
          oral: cat?.oral || "", // Add default values for oral and poster
          poster: cat?.poster || "",
        }));

      console.log("missingCategories", missingCategories);

      // Combine updated and missing categories
      const combinedCategories = [...updatedCategories, ...missingCategories];

      console.log("combinedCategories", combinedCategories);

      // Update setList for physical and virtual categories
      setList = setList?.map((elm) => ({
        ...elm,
        physical: cloneDeep(combinedCategories),
        virtual: cloneDeep(combinedCategories),
      }));
    }

    console.log("setList", setList);

    // Ensure unique and valid categories
    setList = setList.map((elm) => ({
      ...elm,
      physical: unionBy(elm.physical, "category")?.filter((o) => o?.id),
      virtual: unionBy(elm.virtual, "category")?.filter((o) => o?.id),
    }));

    //original Stage code
    //   if (event_pricing.length > 0) {
    //     // const existingCategories = event_pricing[0]?.physical.map(
    //     //   (item) => item.category
    //     // );
    //       const existingCategories = event_pricing.map((item) => ({
    //   id: item?.id,
    //   category: item?.category,
    //   physical: item?.physical || [],
    //   virtual: item?.virtual || [],
    //   updated: item?.updated || "",
    // }));
    //     console.log("existingCategories",existingCategories)

    //     const updatedCategories = existingCategories.map((existing) => {
    //       const replacement = categories.find((cat) => cat.id === existing.id);

    //       // If matching `id`, update the category name if different
    //       if (replacement) {
    //         return {
    //           ...existing,
    //           category: replacement.category, // Update category name
    //           physical: unionBy(existing.physical, replacement.physical, "id"),
    //           virtual: unionBy(existing.virtual, replacement.virtual, "id"),
    //         };
    //       }

    //       // Keep existing if no replacement found
    //       return existing;
    //     });

    //     console.log("updatedCategories",updatedCategories)

    //     // const missingCategories = categories.filter(
    //     //   (cat) => !existingCategories.includes(cat.category)
    //     // );
    //     const missingCategories = categories.filter(
    //       (cat) => !existingCategories.some((exist) => exist.id === cat.id)
    //     );
    //     console.log("missingCategories",missingCategories)

    //     if (missingCategories.length > 0) {
    //       setList = setList.map((elm) => ({
    //         ...elm,
    //         physical: cloneDeep([...elm.physical, ...missingCategories.map((cat) => cat.physical)]),
    //         virtual: cloneDeep([...elm.virtual, ...missingCategories.map((cat) => cat.virtual)]),
    //       }));
    //     } else {
    //       setList = setList.map((elm) => ({
    //         ...elm,
    //         physical: cloneDeep(updatedCategories.flatMap((cat) => cat.physical)),
    //         virtual: cloneDeep(updatedCategories.flatMap((cat) => cat.virtual)),
    //       }));
    //     }
    //   }
    //   console.log("setList",setList)

    //   setList = setList.map((elm) => {
    //     const uniquePhysical = unionBy(elm.physical, "category")?.filter(
    //       (o) => o?.id
    //     );
    //     const uniqueVirtual = unionBy(elm.virtual, "category")?.filter(
    //       (o) => o?.id
    //     );

    //     return {
    //       ...elm,
    //       physical: uniquePhysical,
    //       virtual: uniqueVirtual,
    //     };
    //   });

    console.log("setList", setList);

    // Prepare final data
    const newData = {
      ...data,
      pricing_categories:
        pricing_categories?.length > 0
          ? pricing_categories
          : [{ id: "", category: "" }],
      event_pricing: setList,
      add_on_pricing:
        add_on_pricing.length > 0
          ? add_on_pricing
          : [
              {
                id: "",
                category: "",
                premium_members: "",
                non_premium_members: "",
              },
            ],
      dashboard_pricing_selection: memberList,
      admin_status: admin_status,
    };

    // console.log("newData",newData);

    // Set final data for both view and edit modes
    setData(newData);
    setOldData(newData);
    setIsLoading(false);
  };

  const fetchReviewrDetails = async () => {
    const response = await dispatch(
      getEventRegistrationDetails({ id: requestId })
    );
    const resData = response?.data;
    if (resData) {
      const { pricing_categories } = response?.data || {};
      setOriginalResponse(resData);
      setCategory(pricing_categories || []);
      getData(resData);
    } else {
      setIsLoading(false);
    }
  };

  // dropdown list
  const getEventPriceCategories = async () => {
    const response = await dispatch(fetchEventPriceCategories());
    setPricingCategoriesList(response?.data?.event_pricing || []);
    setRegistrationCategoriesList(response?.data?.pricing_category || []);
    setIsCateLoading(false);
  };

  // const fetchEventDetails = async () => {
  //   if (getDataFromLocalStorage("user_type") === "0") {
  //     await dispatch(getEvent(eventId));
  //   } else {
  //     await dispatch(
  //       fetchUserEventDetails({
  //         event_id: eventId,
  //         user_id: getDataFromLocalStorage("id"),
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    // fetchEventDetails();
    // dispatch(fetchMemberships());
    getEventPriceCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isResourceView || isAdminView) {
      fetchReviewrDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResourceView, isAdminView]);

  const {
    pricing_categories: pricingCategories,
    event_pricing: eventPricing,
    add_on_pricing: addOnPricing,
    dashboard_pricing_selection: dashboardPricingSelection,
    admin_status,
  } = data || {};
  const isPriceBtn = pricingCategories.every((o) => o.category);
  const isAddOnBtn = addOnPricing.every(
    (o) => o.category && o.premium_members && o.non_premium_members
  );
  let isEventBtn = true;
  forEach(eventPricing, (elm) => {
    if (
      !elm.category ||
      elm.physical.some((o) => !o.oral || !o.poster) ||
      elm.virtual.some((o) => !o.oral || !o.poster)
    ) {
      isEventBtn = false;
    }
  });
  let isAnyCheck = false;
  forEach(dashboardPricingSelection, (o) => {
    forEach(o.data, (c) => {
      if (c.is_checked) {
        isAnyCheck = true;
      }
    });
  });

  const isCategory =
    pricingCategories && pricingCategories[0]?.id ? true : false;
  const isbtnEnable = isEqual(oldData, data) || !isEventBtn || !isAnyCheck;
  const isCategoryBtn = pricingCategories?.every((o) => !o.category);

  console.log(isbtnEnable);

  // const filteredPricingCategoriesList =  pricingCategoriesList?.filter(
  //   (o) => o?.status === "1"
  // );

  // const filteredRegistrationCategoriesList = registrationCategoriesList?.filter(
  //   (o) => o?.status === "1"
  // );

  // const filteredPricingCategoriesList = pricingCategoriesList;

  const rejectError = "Please enter reject reason";

  // console.log("isEditable:", isEditable);

  // const filteredPricingCategoriesList = pricingCategoriesList?.filter(
  //     (o) =>
  //       o?.status === "1"
  //   );

  const usePricingCategoriesFilteredData = (
    pricingCategories,
    pricingCategoriesList
  ) => {
    const categories = new Set(
      pricingCategories?.map((item) => item?.category)
    );
    return pricingCategoriesList?.filter(
      (item) => categories?.has(item?.name) || item?.status === "1"
    );
  };

  const useRegistrationCategoriesFilteredData = (
    eventPricing,
    registrationCategoriesList
  ) => {
    const categories = new Set(eventPricing?.map((item) => item?.category));
    return registrationCategoriesList?.filter(
      (item) => categories?.has(item?.name) || item?.status === "1"
    );
  };

  const newFilteredPricingCategoriesList =
    usePricingCategoriesFilteredData(
      pricingCategories,
      pricingCategoriesList
    ) || [];

  const newFilteredRegistrationCategoriesList =
    useRegistrationCategoriesFilteredData(
      eventPricing,
      registrationCategoriesList
    ) || [];
  console.log(
    "newFilteredRegistrationCategoriesList:",
    newFilteredRegistrationCategoriesList
  );
  console.log("eventPricing", eventPricing);

  // console.log("newFilteredPricingCategoriesList(",newFilteredPricingCategoriesList);

  return (
    <>
      <Card className={"cps-24 cpe-24 cpb-24 cmb-12"}>
        {isLoading ? (
          <div className="cpt-80 cpb-80">
            <Loader size="md" />
          </div>
        ) : (
          <div id="new-registration-event-form-block">
            <div className="d-flex gap-2 align-items-center mb-3 cpt-20">
              <div
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  src={icons.leftArrow}
                  alt="back"
                  className="h-21 me-3 pointer"
                />
              </div>

              <div className="text-18-500 color-black-olive">
                Pricing Categories
              </div>
            </div>
            {/* {allArraysEmpty ? ( */}
            <>
              {pricingCategories?.map((elem, index) => {
                return (
                  <div className="row" key={index}>
                    {/* {elem?.category ? ( */}
                    {isEditable && admin_status === "0" ? (
                      elem?.category ? (
                        <div className="col-xl-6 col-md-6 cmb-22">
                          <CreatablePricingDropdown
                            label={`Pricing Category ${index + 1}`}
                            id={`pricing_categories-${index}`}
                            placeholder="Select Price Category"
                            options={newFilteredPricingCategoriesList}
                            optionKey={"name"}
                            optionValue={"name"}
                            value={elem?.category}
                            onChange={(e) => {
                              handelChangeInput(e);
                            }}
                            isLoading={isCateLoading}
                            selectedList={pricingCategories || []}
                            isBadge={elem?.updated === "1" || elem?.resource_id}
                          />
                        </div>
                      ) : (
                        <div className="col-xl-6 col-md-6 cmb-22">
                          <CreatablePricingDropdown
                            label={`Pricing Category ${index + 1}`}
                            id={`pricing_categories-${index}`}
                            placeholder="Select Price Category"
                            options={newFilteredPricingCategoriesList}
                            value={elem?.category}
                            onChange={(e) => {
                              handelChangeInput(e);
                            }}
                            isLoading={isCateLoading}
                            selectedList={pricingCategories || []}
                            isBadge={elem?.updated === "1" || elem?.resource_id}
                          />
                        </div>
                      )
                    ) : (
                      <div className="col-xl-6 col-md-6 cmb-22">
                        <TextInput
                          label={`Pricing Category ${index + 1}`}
                          id={`pricing_categories-${index}`}
                          placeholder="Price Category"
                          onChange={() => {}}
                          value={elem?.category}
                          isBadge={elem?.updated === "1" || elem?.resource_id}
                        />
                      </div>
                    )}

                    {/* ) : (
                      <div className="col-xl-6 col-md-6 cmb-22">
                        <CreatablePricingDropdown
                          label={`Pricing Category ${index + 1}`}
                          id={`pricing_categories-${index}`}
                          placeholder="Select Price Category"
                          onChange={handelChangeInput}
                          options={filteredPricingCategoriesList}
                          value={elem?.category}
                          isLoading={isCateLoading}
                        />
                      </div>
                    )} */}

                    {/* Orignal code */}
                    {/* {elem?.category ? (
                        <div className="col-xl-6 col-md-6 cmb-22">
                          <Dropdown
                            label={`Pricing Category ${index + 1}`}
                            id={`pricing_categories-${index}`}
                            placeholder="Select Price Category"
                            onChange={handelChangeInput}
                            options={filteredPricingCategoriesList}
                            optionKey={"name"}
                            optionValue={"name"}
                            value={elem?.category}
                            isLoading={isCateLoading}
                            isBadge={elem?.updated === "1"}
                          />
                        </div>
                      ) : (
                        <div className="col-xl-6 col-md-6 cmb-22">
                          <Dropdown
                            label={`Pricing Category ${index + 1}`}
                            id={`pricing_categories-${index}`}
                            placeholder="Select Price Category"
                            onChange={handelChangeInput}
                            options={filteredPricingCategoriesList}
                            optionKey={"name"}
                            optionValue={"name"}
                            value={elem?.category}
                            isLoading={isCateLoading}
                          />
                        </div>
                      )} */}
                    <div
                      className={`${
                        isEditable ? "" : "d-none"
                      } col-xl-6 col-md-6 d-flex align-items-end cmb-22`}
                    >
                      {admin_status === "0" &&
                        pricingCategories?.length > 1 && (
                          <Button
                            isSquare
                            btnStyle="primary-gray"
                            text="Delete"
                            className="pt-2 pb-2 ps-3 pe-3"
                            icon={
                              <img
                                src={icons.deleteIcon}
                                alt="delete"
                                className="h-21 me-3"
                              />
                            }
                            onClick={() => {
                              deleteCategory("pricing_categories", index);
                            }}
                          />
                        )}
                    </div>
                  </div>
                );
              })}
              <div
                className={`${
                  isEditable && admin_status === "0" ? "" : "d-none"
                } d-flex gap-3`}
              >
                <Button
                  text={isCategory ? "Update" : "Add"}
                  btnStyle="primary-dark"
                  className="text-15-500 ps-3 pe-3"
                  disabled={
                    isCategory
                      ? isEqual(pricingCategories, category)
                      : isCategoryBtn
                  }
                  onClick={updateCategory}
                  btnLoading={categoryBtnLoading}
                />
                <Button
                  text="+ Add Another Category"
                  btnStyle="primary-light"
                  className="text-15-500 ps-3 pe-3"
                  onClick={() => {
                    addCategory("pricing_categories");
                  }}
                  disabled={!isPriceBtn}
                />
              </div>

              {/* {isCategory && ( */}
              <>
                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Event Pricing
                </div>
                <Table bordered responsive className="mb-0">
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Registration Categories</th>
                      <th className="header-cell" />
                      {category
                        ?.filter((o) => o?.id)
                        ?.map((elem, index) => {
                          return (
                            <th className="header-cell" key={index}>
                              {elem?.category}
                            </th>
                          );
                        })}
                      {eventPricing?.length > 1 && (
                        <th className="header-cell" />
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    {eventPricing?.map((elem, index) => {
                      const { physical, virtual, updated } = elem;
                      const isBadge = updated === "1";
                      return (
                        <React.Fragment key={index}>
                          <tr className="table-body-row-container">
                            <td
                              className="text-14-400 color-black-olive row-cell ps-2 pe-2"
                              rowSpan={2}
                            >
                              {isBadge && (
                                <div className="d-flex justify-content-center cmb-10">
                                  <span className="categories-label-badge">
                                    Updated
                                  </span>
                                </div>
                              )}
                              {/* <Dropdown
                                id={`event_pricing-${index}`}
                                placeholder="Select Category"
                                onChange={handelChangeInput}
                                options={filteredRegistrationCategoriesList}
                                optionKey={"name"}
                                optionValue={"name"}
                                value={elem?.category}
                                isLoading={isCateLoading}
                              /> */}
                              {isEditable && admin_status === "0" ? (
                                <CreatablePricingDropdown
                                  id={`event_pricing-${index}`}
                                  placeholder="Select Category"
                                  // options={filteredRegistrationCategoriesList}
                                  // options={
                                  //   isUpcomingEvent
                                  //     ? showEventPricingCategories
                                  //     : filteredRegistrationCategoriesList
                                  // }
                                  // options={filteredCategories||[]}
                                  options={
                                    newFilteredRegistrationCategoriesList
                                  }
                                  optionKey={"name"}
                                  optionValue={"name"}
                                  value={elem?.category}
                                  onChange={(e) => {
                                    handelChangeInput(e);
                                  }}
                                  isLoading={isCateLoading}
                                  selectedList={eventPricing || []}
                                />
                              ) : (
                                <TextInput
                                  id={`event_pricing-${index}`}
                                  placeholder="Event Category"
                                  onChange={() => {}}
                                  value={elem?.category}
                                />
                              )}
                            </td>
                            <td className="text-16-500 color-black-olive row-cell ps-5 pe-5">
                              Physical
                            </td>
                            {physical.map((pElem, pIndex) => {
                              return (
                                <td className="row-cell ps-2 pe-2" key={pIndex}>
                                  <div className="d-flex gap-2">
                                    <TextInput
                                      id={`event_pricing-${index}-physical-${pIndex}-oral`}
                                      placeholder="Early Bird Price"
                                      onChange={(e) => {
                                        if (
                                          isEditable &&
                                          admin_status === "0"
                                        ) {
                                          handelChangeInput(e);
                                        }
                                      }}
                                      value={pElem.oral}
                                    />
                                    <TextInput
                                      id={`event_pricing-${index}-physical-${pIndex}-poster`}
                                      placeholder="Final Price"
                                      onChange={(e) => {
                                        if (
                                          isEditable &&
                                          admin_status === "0"
                                        ) {
                                          handelChangeInput(e);
                                        }
                                      }}
                                      value={pElem.poster}
                                    />
                                  </div>
                                </td>
                              );
                            })}
                            {eventPricing.length > 1 && (
                              <td
                                className={`${
                                  isEditable && admin_status === "0"
                                    ? ""
                                    : "d-none"
                                } "text-14-400 color-black-olive row-cell ps-2 pe-2"`}
                                rowSpan={2}
                              >
                                <Button
                                  isSquare
                                  btnStyle="light-outline"
                                  className="pt-1 pb-1 ps-2 pe-2 h-35"
                                  icon={
                                    deleteLoading ===
                                    `event_pricing${index}` ? (
                                      ""
                                    ) : (
                                      <img
                                        src={icons.deleteIcon}
                                        alt="delete"
                                        className="h-21"
                                      />
                                    )
                                  }
                                  onClick={() => {
                                    deleteCategory(
                                      "event_pricing",
                                      index,
                                      elem.id
                                    );
                                  }}
                                  btnLoading={
                                    deleteLoading === `event_pricing_${index}`
                                  }
                                />
                              </td>
                            )}
                          </tr>
                          <tr className="table-body-row-container">
                            <td className="text-16-500 color-black-olive row-cell ps-5 pe-5">
                              Virtual
                            </td>
                            {virtual.map((vElem, vIndex) => {
                              return (
                                <td className="row-cell ps-2 pe-2" key={vIndex}>
                                  <div className="d-flex gap-2">
                                    <TextInput
                                      id={`event_pricing-${index}-virtual-${vIndex}-oral`}
                                      placeholder="Early Bird Price"
                                      onChange={(e) => {
                                        if (
                                          isEditable &&
                                          admin_status === "0"
                                        ) {
                                          handelChangeInput(e);
                                        }
                                      }}
                                      value={vElem.oral}
                                    />
                                    <TextInput
                                      id={`event_pricing-${index}-virtual-${vIndex}-poster`}
                                      placeholder="Final Price"
                                      onChange={(e) => {
                                        if (
                                          isEditable &&
                                          admin_status === "0"
                                        ) {
                                          handelChangeInput(e);
                                        }
                                      }}
                                      value={vElem.poster}
                                    />
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                <div
                  className={`${
                    isEditable && admin_status === "0" ? "" : "d-none"
                  } d-flex mt-2`}
                >
                  <Button
                    text="+ Add Another Category"
                    btnStyle="primary-light"
                    className="text-15-500 ps-3 pe-3"
                    disabled={!isEventBtn}
                    onClick={() => {
                      addCategory("event_pricing");
                    }}
                  />
                </div>

                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Add On Pricing
                </div>
                <Table bordered>
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Add On Categories</th>
                      <th className="header-cell">Premium Members</th>
                      <th className="header-cell">Non-Premium Members</th>
                      {addOnPricing.length > 1 && (
                        <th className="header-cell" />
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    {addOnPricing.map((elem, index) => {
                      const { category, premium_members, non_premium_members } =
                        elem;
                      return (
                        <tr className="table-body-row-container" key={index}>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-category`}
                              onChange={(e) => {
                                if (isEditable && admin_status === "0") {
                                  handelChangeInput(e);
                                }
                              }}
                              placeholder="Enter Add ons Category"
                              value={category}
                            />
                          </td>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-premium_members`}
                              onChange={(e) => {
                                if (isEditable && admin_status === "0") {
                                  handelChangeInput(e);
                                }
                              }}
                              placeholder="Enter Price"
                              value={premium_members}
                            />
                          </td>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-non_premium_members`}
                              onChange={(e) => {
                                if (isEditable && admin_status === "0") {
                                  handelChangeInput(e);
                                }
                              }}
                              placeholder="Enter Price"
                              value={non_premium_members}
                            />
                          </td>

                          {addOnPricing.length > 1 && (
                            <td
                              className={`${
                                isEditable && admin_status === "0"
                                  ? ""
                                  : "d-none"
                              } text-14-400 color-black-olive row-cell ps-2 pe-2`}
                            >
                              <Button
                                isSquare
                                btnStyle="light-outline"
                                className="pt-1 pb-1 ps-2 pe-2 h-35"
                                icon={
                                  deleteLoading ===
                                  `add_on_pricing_${index}` ? (
                                    ""
                                  ) : (
                                    <img
                                      src={icons.deleteIcon}
                                      alt="delete"
                                      className="h-21"
                                    />
                                  )
                                }
                                onClick={() => {
                                  deleteCategory(
                                    "add_on_pricing",
                                    index,
                                    elem.id
                                  );
                                }}
                                btnLoading={
                                  deleteLoading === `add_on_pricing_${index}`
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div
                  className={`${
                    isEditable && admin_status === "0" ? "" : "d-none"
                  } d-flex`}
                >
                  <Button
                    text="+ Add Another Category"
                    btnStyle="primary-light"
                    className="text-15-500 ps-3 pe-3"
                    disabled={!isAddOnBtn}
                    onClick={() => {
                      addCategory("add_on_pricing");
                    }}
                  />
                </div>

                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Dashboard Pricing Selection
                </div>
                <Table bordered>
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Registration Categories</th>

                      {category
                        ?.filter((o) => o?.id)
                        ?.map((elem, index) => {
                          return (
                            <th className="header-cell" key={index}>
                              {elem.category}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    <tr className="table-body-row-container">
                      <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                        ALL
                      </td>
                      {category
                        ?.filter((o) => o?.id)
                        ?.map((elem, index) => {
                          let isSelectALL = true;
                          dashboardPricingSelection?.forEach((p) => {
                            p?.data?.forEach((c) => {
                              if (elem?.id === c?.id) {
                                if (!c?.is_checked) {
                                  isSelectALL = false;
                                }
                              }
                            });
                          });
                          return (
                            <td
                              className="text-14-400 color-black-olive row-cell"
                              key={index}
                            >
                              <div className="center-flex">
                                <CheckBox
                                  className="checkbox-size-24"
                                  type="ACTIVE"
                                  onClick={() => {
                                    if (isEditable && admin_status === "0") {
                                      let oldData = cloneDeep(data);
                                      oldData.dashboard_pricing_selection.map(
                                        (p) => {
                                          let newP = p;
                                          newP.data = p.data.map((c) => {
                                            let newC = c;
                                            if (c.id === elem.id) {
                                              newC.is_checked = !isSelectALL;
                                            }
                                            return newC;
                                          });
                                          return newP;
                                        }
                                      );
                                      setData(oldData);
                                    }
                                  }}
                                  isChecked={isSelectALL}
                                />
                              </div>
                            </td>
                          );
                        })}
                    </tr>
                    {dashboardPricingSelection?.map((elem, index) => {
                      return (
                        <tr className="table-body-row-container" key={index}>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            {elem.name}
                          </td>
                          {elem?.data
                            ?.filter((o) => o?.id)
                            ?.map((cElem, cIndex) => {
                              const isBadge = cElem?.update === 1;
                              return (
                                <td
                                  className="text-14-400 color-black-olive row-cell"
                                  key={cIndex}
                                >
                                  <div className="center-flex gap-4 flex-wrap">
                                    <CheckBox
                                      className="checkbox-size-24"
                                      type="PRIMARY-ACTIVE"
                                      onClick={() => {
                                        if (
                                          isEditable &&
                                          admin_status === "0"
                                        ) {
                                          let oldData = cloneDeep(data);
                                          oldData.dashboard_pricing_selection[
                                            index
                                          ].data[cIndex].is_checked =
                                            !cElem.is_checked;
                                          setData(oldData);
                                        }
                                      }}
                                      isChecked={cElem.is_checked}
                                    />
                                    {isBadge && (
                                      <span className="dashboard-pricing-label-badge">
                                        Updated
                                      </span>
                                    )}
                                  </div>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {admin_status === "1" ? (
                  <div className="cmt-20 color-success">
                    {userData?.user_type === "6"
                      ? "Your request has accepted."
                      : "You has accepted this request."}
                  </div>
                ) : admin_status === "2" ? (
                  <div className="cmt-20 color-danger-bar">
                    {userData?.user_type === "6"
                      ? "Your request has rejected."
                      : "You has rejected this request."}
                  </div>
                ) : (
                  userData?.user_type === "0" && (
                    <div className="row cmt-25">
                      <div
                        className={`${
                          isReject ? "cmb-15" : "cmb-45"
                        } col-md-6 `}
                      >
                        <div>
                          <TextArea
                            label="Remarks :"
                            placeholder={"Enter remarks"}
                            id="comment"
                            value={formValues?.comment}
                            onChange={handleChanged}
                          />
                        </div>
                      </div>

                      {isReject && (
                        <>
                          <div className="col-md-6" />
                          <div className="col-md-6 cmb-45">
                            <div>
                              <TextArea
                                label="Reject Reason :"
                                placeholder={"Enter reject reason"}
                                id="reject_remarks"
                                value={formValues?.reject_remarks}
                                error={
                                  !formValues?.reject_remarks ? rejectError : ""
                                }
                                onChange={handleChanged}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-md-6" />
                      <div className="d-flex gap-3 col-md-6">
                        {!isReject && (
                          <Button
                            text="Approve"
                            btnStyle="primary-dark"
                            className="cps-24 cpe-24 h-40"
                            btnLoading={isBtnTypeLoading === 1}
                            onClick={() => {
                              console.log("oldData", JSON.stringify(oldData));
                              console.log("updated data", JSON.stringify(data));

                              const payload = {
                                id: requestId,
                                action_type: 1,
                                comment: formValues?.comment,
                                // data: JSON.stringify(originalResponse),
                                data: JSON.stringify(data),
                              };
                              handleRequest(payload);
                            }}
                          />
                        )}
                        <Button
                          text="Reject"
                          btnStyle={
                            isReject ? "primary-dark" : "primary-light-outline"
                          }
                          btnLoading={isBtnTypeLoading === 2}
                          className="cps-24 cpe-24 cpt-8 cpb-8 h-40"
                          disabled={isReject ? !formValues?.reject_remarks : ""}
                          onClick={() => {
                            if (isReject) {
                              const payload = {
                                id: requestId,
                                action_type: 2,
                                comment: formValues?.comment,
                                reject_remarks: formValues?.reject_remarks,
                                data: JSON.stringify(originalResponse),
                              };
                              handleRequest(payload);
                            } else {
                              setIsReject(true);
                            }
                          }}
                        />
                        <Button
                          text="Back"
                          btnStyle="primary-outline"
                          className="cps-24 cpe-24 cpt-8 cpb-8 h-40"
                          onClick={() => {
                            setData(oldData);
                            setFormValues({
                              comment: "",
                              reject_remarks: "",
                            });
                            if (isReject) {
                              setIsReject(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </>
              {/* )} */}
            </>
            {/* ) : (
              <div className="d-flex justify-content-center text-14-400 cpb-20 cmt-24">
                No Data Found
              </div>
            )} */}
          </div>
        )}
      </Card>
    </>
  );
};

export default ViewNewRegistrationEventForm;

/*const getData = (resData) => {
  const {
    pricing_categories,
    event_pricing: eventPricing,
    add_on_pricing,
    dashboard_pricing_selection,
    admin_status,
  } = resData;
  let event_pricing = eventPricing?.map((el) => {
    return {
      ...el,
      physical: el?.physical || [],
      virtual: el?.virtual || [],
    };
  });
  // console.log("event_pricing",event_pricing);

  const categories = map(pricing_categories, (el) => {
    return { ...el, oral: "", poster: "" };
  });

  const memberList = map(membershipList, (elem) => {
    const findData =
      dashboard_pricing_selection.find((o) => isEqual(o.id + "", elem.id))
        ?.data || [];

    const newfindData = unionBy(
      findData,
      pricing_categories.map((el) => ({ ...el, is_checked: false })),
      "id"
    );

    return {
      ...elem,
      data:
        newfindData.length > 0
          ? newfindData
          : map(pricing_categories, (el) => {
              return { ...el, is_checked: false };
            }),
    };
  });

  // console.log("memberList:", memberList);
  const dataWithOralPosterPrice = [
    {
      id: "",
      category: "",
      physical: cloneDeep(categories),
      virtual: cloneDeep(categories),
    },
  ];

  // console.log("dataWithOralPosterPrice",dataWithOralPosterPrice)

  let setList =
    event_pricing.length > 0 ? event_pricing : dataWithOralPosterPrice;
  // old
  // if (event_pricing.length > 0) {
  //   if (categories.length !== event_pricing[0]?.physical.length) {
  //     let diff = categories.length - event_pricing[0]?.physical.length;
  //     console.log("diff:", diff)

  //     if (diff > 0) {
  //       let arr = [];
  //       categories.forEach((ele) => {
  //         console.log("ele:", ele)
  //         event_pricing[0]?.physical.forEach((phyEle) => {
  //           if (ele.category !== phyEle.category) {
  //             arr.push(ele);
  //           }
  //         });
  //       });

  //       setList = setList.map((elm) => {
  //         return {
  //           ...elm,
  //           physical: cloneDeep([...elm.physical, ...arr]),
  //           virtual: cloneDeep([...elm.virtual, ...arr]),
  //         };
  //       });
  //       console.log("if setList:", setList)
  //     } else if (diff < 0) {
  //       setList = setList.map((elm) => {
  //         return {
  //           ...elm,
  //           physical: cloneDeep(
  //             elm.physical.splice(-diff, elm.physical.length - 1)
  //           ),
  //           virtual: cloneDeep(
  //             elm.virtual.splice(-diff, elm.virtual.length - 1)
  //           ),
  //         };
  //       });
  //       console.log("else if setList:", setList)

  //     } else {
  //       // Nothing
  //     }
  //   }
  // }
  // new
  if (event_pricing.length > 0) {
    const existingCategories = event_pricing[0]?.physical.map(
      (item) => item.category
    );
    const missingCategories = categories.filter(
      (cat) => !existingCategories.includes(cat.category)
    );

    if (missingCategories.length > 0) {
      setList = setList.map((elm) => {
        return {
          ...elm,
          physical: cloneDeep([...elm.physical, ...missingCategories]),
          virtual: cloneDeep([...elm.virtual, ...missingCategories]),
        };
      });
    }
  }
  setList = setList.map((elm) => {
    const uniquePhysical = unionBy(elm.physical, "category")?.filter(
      (o) => o?.id
    );
    const uniqueVirtual = unionBy(elm.virtual, "category")?.filter(
      (o) => o?.id
    );

    return {
      ...elm,
      physical: uniquePhysical,
      virtual: uniqueVirtual,
    };
  });

  const newData = {
    ...data,
    pricing_categories:
      pricing_categories?.length > 0
        ? pricing_categories
        : [{ id: "", category: "" }],
    event_pricing: setList,
    add_on_pricing:
      add_on_pricing.length > 0
        ? add_on_pricing
        : [
            {
              id: "",
              category: "",
              premium_members: "",
              non_premium_members: "",
            },
          ],
    dashboard_pricing_selection: memberList,
    admin_status: admin_status,
  };
  setData(newData);
  setOldData(newData);
  setIsLoading(false);
};*/
