import Dropdown from "components/form/Dropdown";
import TextInput from "components/form/TextInput";
import Modal from "components/Layout/Modal";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import "./AddSegmentPopup.scss";
import Button from "components/form/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConferencesList,
  manageSegment,
  throwError,
  throwSuccess,
} from "store/slices";
import { objectToFormData, objectToQueryParams } from "utils/helpers";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";

const categories = {
  categoryName: [
    {
      name: "New User",
      conditionName: [
        { cName: "Over all conference" },
        { cName: "Only for this conference" },
      ],
    },
    {
      name: "Existing User",
      conditionName: [
        { cName: "Already attend conference" },
        { cName: "Not attended" },
      ],
    },
  ],
};

const AddSegmentPopup = ({
  onHide,
  type,
  handleSuccess,
  isSegmentEvent,
  data,
}) => {

  const { conferencesList } = useSelector((state) => ({
    conferencesList: state.global.conferencesList,
  }));
    const params = useParams();
    const { eID } = params;
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getConferenceList = async () => {
    await dispatch(
      fetchConferencesList(objectToQueryParams({ event_type: "" }))
    );
  };

  const initialValues = {
    event_id: data ? eID : "",
    segment_name: data?.segment_name || "",
    segment_category: data?.segment_category || "",
    category_condition: data?.category_condition || "",
  };
  const validationSchema = Yup.object().shape({
    event_id: Yup.string().required("Conference is required."),
    segment_name: Yup.string().required("Segment name is required."),
  });

  const handleSave = async (values) => {
    setIsLoading(true);
    if (type === "Edit") {
      handleUpdate(values);
    } else {
      handleAdd(values);
    }
  };

  const handleAdd = async (values) => {
    const formData = objectToFormData(values);
    const response = await dispatch(manageSegment(formData));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      onHide();
      handleSuccess();
      if (formRef.current) {
        formRef.current.resetForm();
      } else {
        dispatch(throwError({ message: response?.message }));
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async (values) => {
    const formData = objectToFormData({ ...values, id: data?.segment_id });
    const response = await dispatch(manageSegment(formData));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      onHide();
      handleSuccess();
      if (formRef.current) {
        formRef.current.resetForm();
      } else {
        dispatch(throwError({ message: response?.message }));
      }
    }
    setIsLoading(false);
  };

  const getConditionOptions = (category) => {
    const selectedCategory = categories.categoryName.find(
      (cat) => cat.name === category
    );
    return selectedCategory ? selectedCategory.conditionName : [];
  };

  useEffect(() => {
    getConferenceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      size="lg"
      width={"1320px"}
      onHide={onHide}
      titleBesideClose={"Segment"}
      titleBesideCloseClassName={"cps-15"}
      isTitleBorder
      isTitleVerticalCenter
      className={"add-segment-popup"}
    >
      <div className="cps-32 cpt-32 cpe-32 cpb-32 add-segment-form-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          innerRef={formRef}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit, resetForm } =
              props;
            return (
              <form>
                <div className="row cmb-24 gy-3">
                  <div className="col-md-4">
                    <Dropdown
                      id="event_id"
                      label="Confernece"
                      options={conferencesList}
                      value={values?.event_id}
                      error={errors?.event_id}
                      optionKey={"id"}
                      optionValue={"event_name"}
                      onChange={handleChange}
                      placeholder={"Select Conference"}
                      disabled={isSegmentEvent}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextInput
                      label={"Segment name"}
                      isRequired
                      id={"segment_name"}
                      placeholder={"Enter segment name"}
                      value={values?.segment_name}
                      error={errors?.segment_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="create-segment-container cmb-24">
                  <div className="cps-24 cpt-12 cpb-12 text-14-500-18 color-black-olive bg-f4fc">
                    Create Segment Conditions
                  </div>
                  <div className="cps-24 cpt-24 cpb-27 cpe-24 row">
                    <div className="col-md-4 cmb-31">
                      <Dropdown
                        id="segment_category"
                        label="Category"
                        options={categories.categoryName}
                        value={values?.segment_category}
                        optionKey={"name"}
                        optionValue={"name"}
                        onChange={handleChange}
                        placeholder={"Select Conference"}
                      />
                    </div>
                    {values?.segment_category && (
                      <div className="d-flex align-items-center gap-3">
                        <span className="text-14-500-18 color-1717">
                          Conditions:
                        </span>
                        <div className="">
                          <Dropdown
                            id="category_condition"
                            options={getConditionOptions(
                              values.segment_category
                            )}
                            value={values?.category_condition}
                            optionKey={"cName"}
                            optionValue={"cName"}
                            onChange={handleChange}
                            placeholder={"Select Conference"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <Button
                    text={`${type === "Edit" ? "Update" : "Submit"}`}
                    btnStyle="primary-dark"
                    className="h-35 cps-20 cpe-20"
                    onClick={handleSubmit}
                    btnLoading={isLoading}
                    disabled={isEqual(initialValues, values)}
                  />
                  <Button
                    text="Cancel"
                    btnStyle="light-outline"
                    className="h-35 cps-20 cpe-20"
                    onClick={() => {
                      resetForm();
                    }}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddSegmentPopup;
