import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import TextInput from "components/form/TextInput";
import { Table } from "react-bootstrap";
import { cloneDeep, filter, forEach, isEqual, map, unionBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAddOnPricing,
  deleteEventPricing,
  fetchEventPriceCategories,
  fetchEventPricing,
  showSuccess,
  throwError,
  updateEventPricing,
  updatePricingCategory,
} from "store/slices";
import { icons } from "utils/constants";
import {
  getDataFromLocalStorage,
  titleCaseString,
  trimAllSpace,
  trimLeftSpace,
} from "utils/helpers";
// import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
// import Dropdown from "components/form/Dropdown";
import "./NewRegistrationEventForm.scss";
import Card from "components/Layout/Card";
import CreatablePricingDropdown from "components/form/CreatablePricingDropdown";

const NewRegistrationEventForm = ({
  hideButton,
  onHide,
  fetchEventDetails,
  isModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { eventId } = params || {};
  const userData = getDataFromLocalStorage();
  const { allMembershipList, send_to, eventData } = useSelector((state) => ({
    allMembershipList: state.global.membershipList,
    send_to: state.global.eventData.send_to || "",
    eventData: state.global.eventData,
  }));
  const membershipList = filter(allMembershipList, (e) =>
    send_to?.split(",").includes(e.id)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isCateLoading, setIsCateLoading] = useState(false);
  const [pricingCategoriesList, setPricingCategoriesList] = useState([]);
  const [registrationCategoriesList, setRegistrationCategoriesList] = useState(
    []
  );
  const [category, setCategory] = useState([]);
  const [categoryBtnLoading, setCategoryBtnLoading] = useState(false);
  const [deleteLoading, setDeleteBtnLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [oldData, setOldData] = useState(null);

  const [data, setData] = useState({
    pricing_categories: [
      {
        id: "",
        category: "",
      },
    ],
    event_pricing: [],
    add_on_pricing: [],
    dashboard_pricing_selection: [],
  });

  const updateCategory = async () => {
    setCategoryBtnLoading(true);
    let payload = data.pricing_categories?.map((elm) => {
      return { ...elm, event_id: eventId };
    });

    const resposne = await dispatch(
      updatePricingCategory({
        pricing_categories: payload,
      })
    );
    if (resposne.status === 200) {
      localStorage.removeItem("categories");
      getPricing();
      getEventPriceCategories();
    }
    setCategoryBtnLoading(false);
  };

  const addCategory = (type) => {
    let oldData = cloneDeep(data);
    if (type === "pricing_categories") {
      oldData.pricing_categories = [
        ...oldData.pricing_categories,
        { id: "", category: "" },
      ];
    } else if (type === "event_pricing") {
      const categories = map(category, (el) => {
        return { ...el, oral: "", poster: "" };
      });

      oldData.event_pricing = [
        ...oldData.event_pricing,
        {
          id: "",
          physical: cloneDeep(categories),
          virtual: cloneDeep(categories),
        },
      ];
    } else if (type === "add_on_pricing") {
      oldData.add_on_pricing = [
        ...oldData.add_on_pricing,
        { id: "", category: "", premium_members: "", non_premium_members: "" },
      ];
    } else {
      // Nothing
    }
    setData(oldData);
  };

  const deleteCategory = async (type, index, id) => {
    let oldData = cloneDeep(data);
    setDeleteBtnLoading(`${type}_${index}`);
    if (type === "pricing_categories") {
      oldData.pricing_categories.splice(index, 1);
    } else if (type === "event_pricing") {
      if (id) {
        const resDelete = await dispatch(deleteEventPricing(id));
        if (resDelete.status === 200) {
          oldData.event_pricing.splice(index, 1);
        }
      } else {
        oldData.event_pricing.splice(index, 1);
      }
    } else if (type === "add_on_pricing") {
      if (id) {
        const resDelete = await dispatch(deleteAddOnPricing(id));
        if (resDelete.status === 200) {
          oldData.add_on_pricing.splice(index, 1);
        }
      } else {
        oldData.add_on_pricing.splice(index, 1);
      }
    } else {
      // Nothing
    }
    setDeleteBtnLoading("");
    setData(oldData);
  };

  const handelChangeInput = (e) => {
    const id = e.target.id;
    const sID = cloneDeep(id.split("-"));
    let value = trimLeftSpace(titleCaseString(e.target.value));

    let oldData = cloneDeep(data);

    if (sID[0] === "pricing_categories") {
      oldData.pricing_categories[sID[1]].category = value;
    } else if (sID[0] === "event_pricing") {
      // console.log("sid",sID.length > 2)
      if (sID.length > 2) {
        oldData.event_pricing[sID[1]][sID[2]][sID[3]][sID[4]] = trimAllSpace(
          value
        ).replace(/\D/g, "");
        // console.log("else if",oldData.event_pricing)
      } else {
        oldData.event_pricing[sID[1]].category = value;
        // console.log("else ",oldData.event_pricing[sID[1]])
      }
    } else if (sID[0] === "add_on_pricing") {
      if (sID[2] !== "category") {
        value = trimAllSpace(value.replace(/\D/g, ""));
      }
      oldData.add_on_pricing[sID[1]][sID[2]] = value;
    } else {
      // Nothing
    }

    setData(oldData);
  };

  const updatePrice = async (data) => {
    setIsBtnLoading(true);
    const isAddOnBtn = data.add_on_pricing.every(
      (o) => o.category && o.premium_members && o.non_premium_members
    );
    if (!isAddOnBtn) {
      data = { ...data, add_on_pricing: [] };
    }
    const payload = { ...data, event_id: eventId };
    const response = await dispatch(updateEventPricing(payload));
    if (response?.status === 200) {
      localStorage.removeItem("categories");
      getPricing();
      getEventPriceCategories()
      //   if (fetchEventDetails) {
      //     fetchEventDetails();
      //   }
      const text = oldData?.event_pricing?.[0]?.category ? "update" : "add";
      dispatch(showSuccess(`Registration price ${text} successfully.`));
    }
    setIsBtnLoading(false);
  };

  const getData = (resData) => {
    // console.log(resData);
    const {
      pricing_categories,
      event_pricing: eventPricing,
      add_on_pricing,
      dashboard_pricing_selection,
    } = resData;
    let event_pricing = eventPricing?.map((el) => {
      return {
        ...el,
        physical: el?.physical || [],
        virtual: el?.virtual || [],
      };
    });
    const categories = map(pricing_categories, (el) => {
      return { ...el, oral: "", poster: "" };
    });
    const memberList = map(membershipList, (elem) => {
      const findData =
        dashboard_pricing_selection.find((o) => isEqual(o.id + "", elem.id))
          ?.data || [];
      const newfindData = unionBy(
        findData,
        pricing_categories.map((el) => ({ ...el, is_checked: false })),
        "id"
      );
      return {
        ...elem,
        data:
          newfindData.length > 0
            ? newfindData
            : map(pricing_categories, (el) => {
                return { ...el, is_checked: false };
              }),
      };
    });
    const dataWithOralPosterPrice = [
      {
        id: "",
        category: "",
        physical: cloneDeep(categories),
        virtual: cloneDeep(categories),
      },
    ];

    let setList =
      event_pricing.length > 0 ? event_pricing : dataWithOralPosterPrice;

    if (event_pricing.length > 0) {
      if (categories.length !== event_pricing[0]?.physical.length) {
        let diff = categories.length - event_pricing[0]?.physical.length;

        if (diff > 0) {
          // let arr = [];
          // categories.forEach((ele) => {
          //   event_pricing[0]?.physical.forEach((phyEle) => {
          //     console.log("result",ele.category,phyEle.category,ele.category !== phyEle.category);

          //     if (ele.category !== phyEle.category) {
          //       arr.push(ele);
          //     }
          //   });
          // });
          const physicalCategories = new Set(
            event_pricing[0]?.physical.map((phyEle) => phyEle.category)
          );

          // Filter categories to find items not in physicalCategories
          const arr = categories.filter(
            (ele) => !physicalCategories.has(ele.category)
          );

          setList = setList.map((elm) => {
            return {
              ...elm,
              physical: cloneDeep([...elm.physical, ...arr]),
              virtual: cloneDeep([...elm.virtual, ...arr]),
            };
          });
        } else if (diff < 0) {
          setList = setList.map((elm) => {
            return {
              ...elm,
              physical: cloneDeep(
                elm.physical.splice(-diff, elm.physical.length - 1)
              ),
              virtual: cloneDeep(
                elm.virtual.splice(-diff, elm.virtual.length - 1)
              ),
            };
          });
        } else {
          // Nothing
        }
      }
    }
    const newData = {
      ...data,
      pricing_categories:
        pricing_categories?.length > 0
          ? pricing_categories
          : [{ id: "", category: "" }],
      event_pricing: setList,
      add_on_pricing:
        add_on_pricing.length > 0
          ? add_on_pricing
          : [
              {
                id: "",
                category: "",
                premium_members: "",
                non_premium_members: "",
              },
            ],
      dashboard_pricing_selection: memberList,
    };

    setData(newData);
    setOldData(newData);
    setIsLoading(false);
  };

  const getPricing = async () => {
    const response = await dispatch(fetchEventPricing({ event_id: eventId }));
    const resData = response?.data;
    if (resData) {
      const { pricing_categories } = response.data;
      setCategory(pricing_categories);
      getData(resData);
    }
  };

  // dropdown list
  const getEventPriceCategories = async () => {
    const response = await dispatch(fetchEventPriceCategories());
    setPricingCategoriesList(response?.data?.event_pricing || []);
    setRegistrationCategoriesList(response?.data?.pricing_category || []);
    setIsCateLoading(false);
  };

  useEffect(() => {
    getPricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEventPriceCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    pricing_categories: pricingCategories,
    event_pricing: eventPricing,
    add_on_pricing: addOnPricing,
    dashboard_pricing_selection: dashboardPricingSelection,
  } = data;
  const isPriceBtn = pricingCategories.every((o) => o.category);
  const isAddOnBtn = addOnPricing.every(
    (o) => o.category && o.premium_members && o.non_premium_members
  );
  let isEventBtn = true;
  forEach(eventPricing, (elm) => {
    if (
      !elm.category ||
      elm.physical.some((o) => !o.oral || !o.poster) ||
      elm.virtual.some((o) => !o.oral || !o.poster)
    ) {
      isEventBtn = false;
    }
  });
  let isAnyCheck = false;
  forEach(dashboardPricingSelection, (o) => {
    forEach(o.data, (c) => {
      if (c.is_checked) {
        isAnyCheck = true;
      }
    });
  });

  const isCategory =
    pricingCategories && pricingCategories[0]?.id ? true : false;
  const isbtnEnable = isEqual(oldData, data) || !isEventBtn || !isAnyCheck;
  const isCategoryBtn = pricingCategories?.every((o) => !o.category);

  const filteredPricingCategoriesList = pricingCategoriesList?.filter(
    (o) =>
      o?.status === "1" || (o?.user_id === userData?.id && o?.status === "0")
  );

  console.log("isbtnEnable",isbtnEnable);
  console.log("!isEventBtn",!isEventBtn);
  console.log("!isAnyCheck",!isAnyCheck);
  
  // console.log(
  //   "🚀 ~ filteredPricingCategoriesList:",
  //   filteredPricingCategoriesList
  // );
  // const filteredRegistrationCategoriesList = registrationCategoriesList?.filter(
  //   (o) =>
  //     o?.status === "1" || (o?.user_id === userData?.id && o?.status === "0")
  // );

  const Wrapper = eventId && !isModal ? Card : "div";
  const wrapperProps =
    eventId && !isModal ? { className: "cps-24 cpe-24 cpb-24 cmb-12" } : {};

  // const handleCategoryCreate = (newCategory) => {
  //   let newList = filteredPricingCategoriesList;
  //   newList = [...newList, newCategory]; // Add new category to the list
  //   console.log("newlist", newList);
  // };

  const useFilteredData = (pricingCategories, pricingCategoriesList) => {
    const categories = new Set(
      pricingCategories?.map((item) => item?.category)
    );
    return pricingCategoriesList?.filter((item) => categories?.has(item?.name));
  };
  // console.log("pricingCategories", pricingCategories);
  // console.log("pricingCategoriesList", pricingCategoriesList);

  const useFilteredList = (eventPricing, registrationCategoriesList) => {
    const categories = new Set(eventPricing?.map((item) => item?.category));
    return registrationCategoriesList?.filter(
      (item) => categories?.has(item?.name) || ["1"]?.includes(item?.is_display)
    );
  };

  const showPrcingCategories =
    useFilteredData(pricingCategories, pricingCategoriesList) || [];

  const showEventPricingCategories =
    useFilteredList(eventPricing, registrationCategoriesList) || [];

  const isUpcomingEvent = eventData?.status === "Upcoming";

  const filtedListForPresentEvent = filteredPricingCategoriesList?.filter(
    (item) =>
      !showPrcingCategories?.some(
        (category) => category?.id === item?.id || item?.status === "0"
      )
  );

  // const  filtedListForRegistrationCategoriesList = filteredRegistrationCategoriesList?.filter(
  //   (item) =>
  //     !showEventPricingCategories?.some(
  //       (category) => category?.id === item?.id || item?.status === "0"
  //     )
  // );

  // console.log("eventPricing",eventPricing)
  // console.log("registrationCategoriesList",registrationCategoriesList);
  // console.log("showEventPricingCategories",showEventPricingCategories)

  // console.log("isbtnEnable",isbtnEnable)
  // console.log("isEventBtn",isEventBtn)

  // console.log("filteredRegistrationCategoriesList",filteredRegistrationCategoriesList);

  // console.log("filtedListForRegistrationCategoriesList",filtedListForRegistrationCategoriesList)

  const filteredCategories = registrationCategoriesList?.filter((category) => {
    // Check if the category name is present in eventPricing
    const isInEventPricing = eventPricing.some(
      (pricing) => pricing?.category === category?.name
    );

    // Check if status is "1"
    const isActive = category?.status === "1";

    // Check if status is "0" and user_id matches staticUserId
    const isInactiveAndMatchingUser =
      category?.status === "0" && category?.user_id === userData?.id;

    const isVisible = ["0", "1"]?.includes(category?.is_display);

    // Return true if any of the conditions are met
    return (
      isInEventPricing || isActive || isInactiveAndMatchingUser || isVisible
    );
  });

  // console.log("showEventPricingCategories", showEventPricingCategories);
  // console.log("filteredCategories", filteredCategories);
  // console.log("filtedListForPresentEvent",filtedListForPresentEvent)

  return (
    <>
      <Wrapper {...wrapperProps}>
        {isLoading ? (
          <div className="cpt-80 cpb-80">
            <Loader size="md" />
          </div>
        ) : (
          <div id="new-registration-event-form-block">
            <div className="d-flex gap-2 align-items-center mb-3 cpt-20">
              {!isModal && (
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img
                    src={icons.leftArrow}
                    alt="back"
                    className="h-21 me-3 pointer"
                  />
                </div>
              )}

              <div className="text-18-500 color-black-olive">
                Pricing Categories
              </div>
            </div>
            {pricingCategories?.map((elem, index) => {
              const isDisableForUpcoming =
                showPrcingCategories?.find(
                  (o) =>
                    o?.name === elem.category &&
                    o?.status === "0" &&
                    o?.is_display === "1"
                )?.status === "0";

              return (
                <div className="row" key={index}>
                  {elem?.category ? (
                    <div className="col-xl-6 col-md-6 cmb-22">
                      <CreatablePricingDropdown
                        label={`Pricing Category ${index + 1}`}
                        id={`pricing_categories-${index}`}
                        placeholder="Select Price Category"
                        // options={filteredPricingCategoriesList}
                        // options={
                        //   isUpcomingEvent ?
                        //      showPrcingCategories:
                        //      filteredPricingCategoriesList
                        // }
                        options={showPrcingCategories}
                        optionKey={"name"}
                        optionValue={"name"}
                        value={elem?.category}
                        onChange={(e) => {
                          handelChangeInput(e);
                        }}
                        isLoading={isCateLoading}
                        selectedList={pricingCategories || []}
                        disabled={
                          userData?.user_type === "6"
                            ? isUpcomingEvent
                              ? isDisableForUpcoming
                              : ""
                            : ""
                        }
                      />
                    </div>
                  ) : (
                    <div className="col-xl-6 col-md-6 cmb-22">
                      <CreatablePricingDropdown
                        label={`Pricing Category ${index + 1}`}
                        id={`pricing_categories-${index}`}
                        placeholder="Select Price Category"
                        // options={filteredPricingCategoriesList}
                        //  options={
                        //   isUpcomingEvent ?
                        //      showPrcingCategories:
                        //      filteredPricingCategoriesList
                        // }
                        // options={
                        //   isUpcomingEvent
                        //     ? showPrcingCategories
                        //     : filtedListForPresentEvent
                        // }
                        options={filtedListForPresentEvent}
                        value={elem?.category}
                        onChange={(e) => {
                          handelChangeInput(e);
                        }}
                        isLoading={isCateLoading}
                        selectedList={pricingCategories || []}
                        disabled={
                          userData?.user_type === "6"
                            ? isUpcomingEvent
                              ? isDisableForUpcoming
                              : ""
                            : ""
                        }
                      />
                    </div>
                  )}

                  {/* orignal code */}
                  {/* {elem?.category ? (
                    <div className="col-xl-6 col-md-6 cmb-22">
                      <Dropdown
                        label={`Pricing Category ${index + 1}`}
                        id={`pricing_categories-${index}`}
                        placeholder="Select Price Category"
                        onChange={handelChangeInput}
                        options={filteredPricingCategoriesList}
                        optionKey={"name"}
                        optionValue={"name"}
                        value={elem?.category}
                        isLoading={isCateLoading}
                      />
                    </div>
                  ) : ( */}
                  {/* <div className="col-xl-6 col-md-6 cmb-22"> */}
                  {/* <CreatablePricingDropdown
                        label={`Pricing Category ${index + 1}`}
                        id={`pricing_categories-${index}`}
                        placeholder="Select Price Category"
                        options={filteredPricingCategoriesList}
                        value={elem?.category}
                        onChange={(e)=>{
                          console.log("e",e)
                          handelChangeInput(e)
                        }}
                        isLoading={isCateLoading}
                        onCategoryCreate={handleCategoryCreate}

                      /> */}

                  {/* <Dropdown
                        label={`Pricing Category ${index + 1}`}
                        id={`pricing_categories-${index}`}
                        placeholder="Select Price Category"
                        onChange={handelChangeInput}
                        options={filteredPricingCategoriesList}
                        optionKey={"name"}
                        optionValue={"name"}
                        value={elem?.category}
                        isLoading={isCateLoading}
                      /> */}
                  {/* </div> */}
                  {/* )} */}
                  <div className="col-xl-6 col-md-6 d-flex align-items-end cmb-22">
                    {pricingCategories?.length > 1 && (
                      <Button
                        isSquare
                        btnStyle="primary-gray"
                        text="Delete"
                        className="pt-2 pb-2 ps-3 pe-3"
                        icon={
                          <img
                            src={icons.deleteIcon}
                            alt="delete"
                            className="h-21 me-3"
                          />
                        }
                        onClick={() => {
                          deleteCategory("pricing_categories", index);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <div className="d-flex gap-3">
              <Button
                text={isCategory ? "Update" : "Add"}
                btnStyle="primary-dark"
                className="text-15-500 ps-3 pe-3"
                disabled={
                  isCategory
                    ? isEqual(pricingCategories, category)
                    : isCategoryBtn
                }
                onClick={updateCategory}
                btnLoading={categoryBtnLoading}
              />
              <Button
                text="+ Add Another Category"
                btnStyle="primary-light"
                className="text-15-500 ps-3 pe-3"
                onClick={() => {
                  addCategory("pricing_categories");
                }}
                disabled={!isPriceBtn}
              />
            </div>
            {isCategory && (
              <>
                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Event Pricing
                </div>
                <Table bordered responsive className="mb-0">
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Registration Categories</th>
                      <th className="header-cell" />
                      {category?.map((elem, index) => {
                        return (
                          <th className="header-cell" key={index}>
                            {elem?.category}
                          </th>
                        );
                      })}
                      {eventPricing?.length > 1 && (
                        <th className="header-cell" />
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    {eventPricing?.map((elem, index) => {
                      const { physical, virtual } = elem;
                      const isDisableForUpcomingEventPricing =
                        showEventPricingCategories?.find(
                          (o) =>
                            o?.name === elem.category &&
                            o?.status === "0" &&
                            o?.is_display === "1"
                        )?.status === "0";

                      return (
                        <React.Fragment key={index}>
                          <tr className="table-body-row-container">
                            <td
                              className="text-14-400 color-black-olive row-cell ps-2 pe-2"
                              rowSpan={2}
                            >
                              <CreatablePricingDropdown
                                id={`event_pricing-${index}`}
                                placeholder="Select Category"
                                // options={filteredRegistrationCategoriesList}
                                // options={
                                //   isUpcomingEvent
                                //     ? showEventPricingCategories
                                //     : filteredRegistrationCategoriesList
                                // }
                                // options={filteredCategories||[]}
                                options={
                                  isUpcomingEvent
                                    ? showEventPricingCategories
                                    : filteredCategories
                                }
                                optionKey={"name"}
                                optionValue={"name"}
                                value={elem?.category}
                                onChange={(e) => {
                                  handelChangeInput(e);
                                }}
                                isLoading={isCateLoading}
                                selectedList={eventPricing || []}
                                disabled={
                                  userData?.user_type === "6"
                                    ? isUpcomingEvent
                                      ? isDisableForUpcomingEventPricing
                                      : ""
                                    : ""
                                }
                              />
                              {/* <Dropdown
                                id={`event_pricing-${index}`}
                                placeholder="Select Category"
                                onChange={handelChangeInput}
                                options={filteredRegistrationCategoriesList}
                                optionKey={"name"}
                                optionValue={"name"}
                                value={elem?.category}
                                isLoading={isCateLoading}
                              /> */}
                            </td>
                            <td className="text-16-500 color-black-olive row-cell ps-5 pe-5">
                              Physical
                            </td>
                            {physical.map((pElem, pIndex) => {
                              return (
                                <td className="row-cell ps-2 pe-2" key={pIndex}>
                                  <div className="d-flex gap-2">
                                    <TextInput
                                      id={`event_pricing-${index}-physical-${pIndex}-oral`}
                                      placeholder="Early Bird Price"
                                      onChange={handelChangeInput}
                                      value={pElem.oral}
                                    />
                                    <TextInput
                                      id={`event_pricing-${index}-physical-${pIndex}-poster`}
                                      placeholder="Final Price"
                                      onChange={handelChangeInput}
                                      value={pElem.poster}
                                    />
                                  </div>
                                </td>
                              );
                            })}
                            {eventPricing.length > 1 && (
                              <td
                                className="text-14-400 color-black-olive row-cell ps-2 pe-2"
                                rowSpan={2}
                              >
                                <Button
                                  isSquare
                                  btnStyle="light-outline"
                                  className="pt-1 pb-1 ps-2 pe-2 h-35"
                                  icon={
                                    deleteLoading ===
                                    `event_pricing${index}` ? (
                                      ""
                                    ) : (
                                      <img
                                        src={icons.deleteIcon}
                                        alt="delete"
                                        className="h-21"
                                      />
                                    )
                                  }
                                  onClick={() => {
                                    deleteCategory(
                                      "event_pricing",
                                      index,
                                      elem.id
                                    );
                                  }}
                                  btnLoading={
                                    deleteLoading === `event_pricing_${index}`
                                  }
                                />
                              </td>
                            )}
                          </tr>
                          <tr className="table-body-row-container">
                            <td className="text-16-500 color-black-olive row-cell ps-5 pe-5">
                              Virtual
                            </td>
                            {virtual.map((vElem, vIndex) => {
                              return (
                                <td className="row-cell ps-2 pe-2" key={vIndex}>
                                  <div className="d-flex gap-2">
                                    <TextInput
                                      id={`event_pricing-${index}-virtual-${vIndex}-oral`}
                                      placeholder="Early Bird Price"
                                      onChange={handelChangeInput}
                                      value={vElem.oral}
                                    />
                                    <TextInput
                                      id={`event_pricing-${index}-virtual-${vIndex}-poster`}
                                      placeholder="Final Price"
                                      onChange={handelChangeInput}
                                      value={vElem.poster}
                                    />
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-flex mt-2">
                  <Button
                    text="+ Add Another Category"
                    btnStyle="primary-light"
                    className="text-15-500 ps-3 pe-3"
                    // disabled={!isEventBtn}
                    onClick={() => {
                      addCategory("event_pricing");
                    }}
                  />
                </div>

                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Add On Pricing
                </div>
                <Table bordered>
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Add On Categories</th>
                      <th className="header-cell">Premium Members</th>
                      <th className="header-cell">Non-Premium Members</th>
                      {addOnPricing.length > 1 && (
                        <th className="header-cell" />
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    {addOnPricing.map((elem, index) => {
                      const { category, premium_members, non_premium_members } =
                        elem;
                      return (
                        <tr className="table-body-row-container" key={index}>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-category`}
                              onChange={handelChangeInput}
                              placeholder="Enter Add ons Category"
                              value={category}
                            />
                          </td>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-premium_members`}
                              onChange={handelChangeInput}
                              placeholder="Enter Price"
                              value={premium_members}
                            />
                          </td>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            <TextInput
                              id={`add_on_pricing-${index}-non_premium_members`}
                              onChange={handelChangeInput}
                              placeholder="Enter Price"
                              value={non_premium_members}
                            />
                          </td>

                          {addOnPricing.length > 1 && (
                            <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                              <Button
                                isSquare
                                btnStyle="light-outline"
                                className="pt-1 pb-1 ps-2 pe-2 h-35"
                                icon={
                                  deleteLoading ===
                                  `add_on_pricing_${index}` ? (
                                    ""
                                  ) : (
                                    <img
                                      src={icons.deleteIcon}
                                      alt="delete"
                                      className="h-21"
                                    />
                                  )
                                }
                                onClick={() => {
                                  deleteCategory(
                                    "add_on_pricing",
                                    index,
                                    elem.id
                                  );
                                }}
                                btnLoading={
                                  deleteLoading === `add_on_pricing_${index}`
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-flex">
                  <Button
                    text="+ Add Another Category"
                    btnStyle="primary-light"
                    className="text-15-500 ps-3 pe-3"
                    disabled={!isAddOnBtn}
                    onClick={() => {
                      addCategory("add_on_pricing");
                    }}
                  />
                </div>

                <div className="text-18-500 color-black-olive mb-3 cpt-30">
                  Dashboard Pricing Selection
                </div>
                <Table bordered>
                  <thead className="table-header-container">
                    <tr className="table-header-row-container">
                      <th className="header-cell">Registration Categories</th>

                      {category.map((elem, index) => {
                        return (
                          <th className="header-cell" key={index}>
                            {elem.category}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="table-body-container">
                    <tr className="table-body-row-container">
                      <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                        ALL
                      </td>
                      {category.map((elem, index) => {
                        let isSelectALL = true;
                        dashboardPricingSelection?.forEach((p) => {
                          p?.data?.forEach((c) => {
                            if (elem?.id === c?.id) {
                              if (!c?.is_checked) {
                                isSelectALL = false;
                              }
                            }
                          });
                        });
                        return (
                          <td
                            className="text-14-400 color-black-olive row-cell"
                            key={index}
                          >
                            <div className="center-flex">
                              <CheckBox
                                className="checkbox-size-24"
                                type="ACTIVE"
                                onClick={() => {
                                  let oldData = cloneDeep(data);
                                  oldData.dashboard_pricing_selection.map(
                                    (p) => {
                                      let newP = p;
                                      newP.data = p.data.map((c) => {
                                        let newC = c;
                                        if (c.id === elem.id) {
                                          newC.is_checked = !isSelectALL;
                                        }
                                        return newC;
                                      });
                                      return newP;
                                    }
                                  );
                                  // console.log(
                                  //   oldData.dashboard_pricing_selection
                                  // );
                                  // oldData.dashboard_pricing_selection[
                                  //   index
                                  // ].data[cIndex].is_checked =
                                  //   !cElem.is_checked;
                                  setData(oldData);
                                }}
                                isChecked={isSelectALL}
                              />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    {dashboardPricingSelection?.map((elem, index) => {
                      return (
                        <tr className="table-body-row-container" key={index}>
                          <td className="text-14-400 color-black-olive row-cell ps-2 pe-2">
                            {elem.name}
                          </td>
                          {elem?.data.map((cElem, cIndex) => {
                            return (
                              <td
                                className="text-14-400 color-black-olive row-cell"
                                key={cIndex}
                              >
                                <div className="center-flex">
                                  <CheckBox
                                    className="checkbox-size-24"
                                    type="PRIMARY-ACTIVE"
                                    onClick={() => {
                                      let oldData = cloneDeep(data);

                                      oldData.dashboard_pricing_selection[
                                        index
                                      ].data[cIndex].is_checked =
                                        !cElem.is_checked;
                                      setData(oldData);
                                    }}
                                    isChecked={cElem.is_checked}
                                  />
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!hideButton && (
                  <div className="d-flex justify-content-center gap-4 cmt-40">
                    <Button
                      isRounded
                      text="Cancel"
                      btnStyle="light-outline"
                      className="cps-40 cpe-40"
                      onClick={() => {
                        setData(oldData);
                      }}
                    />
                    <Button
                      isRounded
                      text={
                        userData?.user_type === "6" ? "Send Request" : "Done"
                      }
                      btnStyle="primary-dark"
                      className="cps-40 cpe-40"
                      btnLoading={isBtnLoading}
                      disabled={isbtnEnable }
                      onClick={() => {
                        if (!isEventBtn) {
                          dispatch(
                            throwError({
                              message:
                                "Please enter a amount in the event pricing table.",
                            })
                          );
                          return;
                        }
                        updatePrice(data);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default NewRegistrationEventForm;
