import Modal from "components/Layout/Modal";
import React, { useEffect, useState } from "react";
// import SegmentAllocatedUserDropdown from "../SegmentUsers/SegmentAllocatedUserDropdown";
import Profile from "components/Layout/Profile";
import Button from "components/form/Button";
import SegmentAllocatedUserDropdown from "../SegmentUsers/SegmentAllocatedUserDropdown";
import { useDispatch } from "react-redux";
import {
  addSegmentUsers,
  fetchAddSegmentUserList,
  throwSuccess,
} from "store/slices";
import { objectToFormData } from "utils/helpers";
import "./AddUserPopup.scss";

const AddUserPopup = ({ onHide, segmentId, handelSuccess }) => {
  const dispatch = useDispatch();
  const allocated_time = true;
  // const [userDeleteId, setUserDeleteId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const fetchList = async () => {
    const response = await dispatch(
      fetchAddSegmentUserList(objectToFormData({ id: segmentId }))
    );
    setList(response?.data?.user_details || []);
    setIsLoading(false);
  };

  const handleDelete = (deleteUserID) => {
    setSelectedUserList(
      selectedUserList?.filter((user) => user?.id !== deleteUserID)
    );
  };

  const handleAddUsers = async () => {
    setIsDeleteLoader(true);
    const payload = {
      id: selectedUserList?.map((o) => o?.id)?.join(","),
      segment_id: segmentId,
    };
    const response = await dispatch(addSegmentUsers(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      setSelectedUserList([]);
      onHide();
      handelSuccess();
    }
    setIsDeleteLoader(false);
  };
  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      size="lg"
      width={"1320px"}
      onHide={onHide}
      titleBesideClose={"Add Users"}
      titleBesideCloseClassName={"cps-15"}
      isTitleBorder
      isTitleVerticalCenter
      className={"add-user-popup"}
    >
      {/* {userDeleteId && (
        <DeletePopup
          title="Delete Users"
          message="Are you sure want to delete user ?"
          id={userDeleteId}
          onHide={() => {
            setUserDeleteId(false);
          }}
          handelSuccess={() => {
            setUserDeleteId(false);
          }}
          handelDelete={async () => {}}
        />
      )} */}
      <div className="cpt-20 cpb-20 add-user-container">
        <div className="d-flex justify-content-center gap-3 align-items-center flex-wrap">
          <SegmentAllocatedUserDropdown
            label={"Users"}
            isRequired
            placeholder={"Search Name or Email 0r Mobile"}
            optionValue="name"
            optionKey="id"
            options={list}
            isPreviousUsers={selectedUserList}
            isLoading={isLoading}
            onChange={(userList) => {
              setSelectedUserList(userList);
            }}
          />
        </div>

        <div className="cmt-50 cps-32 cpe-32">
          <div className="row cmb-46">
            {selectedUserList?.map((o, index) => {
              const { id, name, profile_image, email_id, member_id, phone_no } =
                o || {};
              return (
                <div className={`col-md-4 py-2`} key={index}>
                  <div
                    className={`${allocated_time ? "pointer" : ""} 
            
                  cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
                  >
                    <span
                      className="position-absolute pointer"
                      style={{ right: "20px" }}
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <i className="bi bi-x-lg" />
                    </span>

                    <div className="row">
                      {/* Image Section */}
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                        <div style={{ height: "114px" }}>
                          <Profile
                            url={profile_image}
                            size="s-114"
                            isS3UserURL
                            text={name}
                          />
                        </div>
                      </div>

                      {/* Info Section */}
                      <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                        <div className="d-flex flex-column">
                          <div className="text-14-400-18 color-new-car cmb-15">
                            {name}
                          </div>
                          <div className="text-12-400-15 color-new-car cmb-15">
                            {member_id}
                          </div>
                          <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                            <i className="bi bi-envelope" />
                            {email_id}
                          </div>
                          <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                            <i className="bi bi-telephone" /> {phone_no}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {selectedUserList?.length !== 0 && (
            <div className="d-flex align-items-center gap-3">
              <Button
                text={"Submit"}
                btnStyle="primary-dark"
                className="h-35 cps-20 cpe-20"
                onClick={handleAddUsers}
                btnLoading={isDeleteLoader}
              />
              <Button
                text="Cancel"
                btnStyle="light-outline"
                className="h-35 cps-20 cpe-20"
                onClick={() => {
                  onHide();
                  setSelectedUserList();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddUserPopup;
