import Button from "components/form/Button";
import CreatableCityDropDown from "components/form/CreatableCityDropDown";
import Dropdown from "components/form/Dropdown";
import FileUpload from "components/form/FileUpload";
import Location from "components/form/Location";
import MultipleSelect from "components/form/MultipleSelect";
import RadioInput from "components/form/RadioInput";
import TextInput from "components/form/TextInput";
import { Formik } from "formik";
import { isEqual } from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  singleBulkRegistrationPayment,
  throwError,
  throwSuccess,
} from "store/slices";
import {
  getDataFromLocalStorage,
  getFilenameFromUrl,
  objectToFormData,
} from "utils/helpers";
import * as Yup from "yup";

const SingleForm = ({ type, paperData, handleSuccess, bulkPaymentDetails }) => {
  const params = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isCoAuthorBlock, setIsCoAuthorBlock] = useState(false);
  const initialValues = {
    abstract_id: type === "1" ? params?.paperId : "",
    payment_by: getDataFromLocalStorage("id"),
    presentation_type: "",
    payment_category: "",
    payment_mode: "",
    payment_proof: "",
    payment_proof_name: "",
    payment_remarks: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zip_code: "",
    payment_type: type,
    amount: "",
    reference_no: "",
    country_name: "",
    state_name: "",
    bulk_paper_id: "",
    bulk_paper_title: "",
    bulk_presentation_type:
      type === "2"
        ? bulkPaymentDetails?.author_details?.map((a) => ({
            id: a.id,
            presentation_type: a.presentation_type,
            author: a.author,
          }))
        : [],
  };
  const validationSchema = Yup.object().shape({
    presentation_type: Yup.string().when("payment_type", {
      is: "1",
      then: Yup.string()
        .required("Presentation type is required")
        .oneOf(["Physical", "Virtual", "Hybrid"], "Invalid presentation type"),
      otherwise: Yup.string(),
    }),
    payment_category: Yup.string().required("Payment category is required"),
    payment_mode: Yup.string().required("Payment mode is required"),
    payment_proof: Yup.mixed().required("Payment proof is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().when("country", {
      is: (val) => !!val,
      then: Yup.string().required("State is required"),
    }),
    city: Yup.string().when("state", {
      is: (val) => !!val,
      then: Yup.string().required("City is required"),
    }),
    address: Yup.string()
      .required("Address is required")
      .max(255, "Address cannot exceed 255 characters"),
    zip_code: Yup.string()
      .required("Zip code is required")
      .matches(/^\d+$/, "Zip code must contain only numbers"),
    amount: Yup.string()
      .required("Amount is required")
      .matches(/^\d+$/, "Amount must contain only numbers")
      .typeError("Amount must be a number"),
    reference_no: Yup.string()
      .required("Reference number is required")
      .max(50, "Reference number cannot exceed 50 characters"),
    bulk_paper_title: Yup.string().when("payment_type", {
      is: "2",
      then: Yup.string().required("Paper title is required."),
      otherwise: Yup.string(),
    }),
    bulk_paper_id: Yup.string().when("payment_type", {
      is: "2",
      then: Yup.string().required("Paper Id is required."),
      otherwise: Yup.string(),
    }),
    bulk_presentation_type: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
          presentation_type: Yup.string()
            .required("Presentation type is required")
            .oneOf(
              ["Physical", "Virtual", "Hybrid"],
              "Invalid presentation type"
            ),
        })
      )
      .when("payment_type", {
        is: "2",
        then: Yup.array().min(1, "At least one presentation type is required"),
        otherwise: Yup.array().notRequired(),
      }),
  });

  const handlePayment = async (value) => {
    setBtnLoading(true);

    const newPayload = {
      ...value,
      presentation_type:
        value?.payment_type === "1"
          ? JSON.stringify([
              {
                id: params?.paperId,
                presentation_type: value?.presentation_type,
              },
            ])
          : JSON.stringify(value?.bulk_presentation_type),
    };

    const response = await dispatch(
      singleBulkRegistrationPayment(objectToFormData(newPayload))
    );

    if (response?.status === 200) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.message }));
    }
    setBtnLoading(false);
  };

  const isLatestPaper = paperData?.latest_paper_title ? paperData?.latest_paper_title :""
let newPaperTitle = isLatestPaper ? paperData?.latest_paper_title :paperData?.paperTitle

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlePayment}
      innerRef={formRef}
    >
      {(props) => {
        const { values, errors, handleChange, setFieldValue, handleSubmit } =
          props;
        const {
          presentation_type,
          payment_category,
          payment_mode,
          payment_proof_name,
          payment_remarks,
          country,
          state,
          city,
          address,
          zip_code,
          amount,
          reference_no,
          bulk_paper_id,
        } = values;
        const bulkCoAuthors = bulkPaymentDetails?.co_author_details?.filter(
          (o) => values?.abstract_id?.includes(o?.id)
        );

        const uniqueAuthors = bulkPaymentDetails?.author_details
          ?.filter((o) => values?.abstract_id?.includes(o?.id))
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.author === value.author)
          );

        return (
          <form>
            <div className="row">
              {type === "1" && (
                <>
                  <div className="col-lg-12 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Paper Name :
                    </div>
                    <div className="text-14-300-24 color-black-olive">
                      {newPaperTitle}
                    </div>
                  </div>

                  <div className="col-lg-4 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Paper ID:
                    </div>
                    <div className="text-14-300-24 color-black-olive">
                      {paperData?.paperId}
                    </div>
                  </div>

                  <div className="col-lg-8 cmb-24"></div>
                </>
              )}

              {type === "2" && (
                <>
                  <div className="col-lg-12 cmb-24">
                    <MultipleSelect
                      label={"Paper Title :"}
                      id="bulk_paper_title"
                      placeholder="select"
                      options={bulkPaymentDetails?.abstract_papers || []}
                      value={values?.bulk_paper_title}
                      error={errors?.bulk_paper_title}
                      optionValue="paper_title"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("abstract_id", e.target.value);
                        setFieldValue("bulk_paper_id", e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-12 cmb-24">
                    <MultipleSelect
                      label={"Paper ID :"}
                      id="bulk_paper_id"
                      placeholder="select"
                      value={bulk_paper_id}
                      error={errors?.bulk_paper_id}
                      options={bulkPaymentDetails?.abstract_papers || []}
                      optionValue="paper_id"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("abstract_id", e.target.value);
                        setFieldValue("bulk_paper_title", e.target.value);
                      }}
                    />
                  </div>
                </>
              )}

              <div className="col-lg-4 cmb-24">
                <div className="cmb-5 text-14-400-18 color-1717">
                  Presentation Type :
                </div>
                <div className="text-14-300-24 color-black-olive">
                  {paperData?.presentation_type}
                </div>
              </div>

              {type === "1" ? (
                <>
                  <div className="col-lg-4 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Author Name :
                    </div>
                    <div className="text-14-300-24 color-black-olive">
                      {paperData?.authorName}
                    </div>
                  </div>
                  <div className="col-lg-4 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Presentation :
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <div className="d-flex align-items-center">
                        <RadioInput
                          name="presentation_type"
                          id="presentation_type"
                          onChange={() => {
                            setFieldValue("presentation_type", "Physical");
                          }}
                          checked={presentation_type === "Physical"}
                        />

                        <div className="text-16-400 color-raisin-black ms-3">
                          Physical
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <RadioInput
                          name="presentation_type"
                          id="presentation_type"
                          onChange={() => {
                            setFieldValue("presentation_type", "Virtual");
                          }}
                          checked={presentation_type === "Virtual"}
                        />

                        <div className="text-16-400 color-raisin-black ms-3">
                          Virtual
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center">
                        <RadioInput
                          name="presentation_type"
                          id="presentation_type"
                          onChange={() => {
                            setFieldValue("presentation_type", "Hybrid");
                          }}
                          checked={presentation_type === "Hybrid"}
                        />

                        <div className="text-16-400 color-raisin-black ms-3">
                          Hybrid
                        </div>
                      </div> */}
                    </div>
                    {errors?.presentation_type && (
                      <span className="text-13-400 pt-1 d-flex align-items-cemter gap-2">
                        <span style={{ color: "red" }}>
                          {errors?.presentation_type}
                        </span>
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-8"></div>
                  {uniqueAuthors.map((o, i) => {
                    const selectedType = values?.bulk_presentation_type?.find(
                      (author) => author?.id === o?.id
                    )?.presentation_type;

                    const handlePresentationTypeChange = (
                      id,
                      type,
                      authorName
                    ) => {
                      const updatedTypes = values?.bulk_presentation_type?.map(
                        (author) =>
                          author?.author === authorName
                            ? { ...author, presentation_type: type }
                            : author
                      );
                      setFieldValue("bulk_presentation_type", updatedTypes);
                    };

                    return (
                      <React.Fragment key={i}>
                        <div className="col-lg-4 cmb-24">
                          <div className="cmb-5 text-14-400-18 color-1717">
                            Author Name {i + 1} :
                          </div>
                          <div className="text-14-300-24 color-black-olive">
                            {o?.author}
                          </div>
                        </div>
                        <div className="col-lg-4 cmb-24">
                          <div className="cmb-5 text-14-400-18 color-1717">
                            Presentation :
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <div className="d-flex align-items-center">
                              <RadioInput
                                name={`bulk_presentation_type_${o?.id}`}
                                id="bulk_presentation_type"
                                onChange={() =>
                                  handlePresentationTypeChange(
                                    o?.id,
                                    "Physical",
                                    o?.author
                                  )
                                }
                                checked={selectedType === "Physical"}
                              />

                              <div className="text-16-400 color-raisin-black ms-3">
                                Physical
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <RadioInput
                                name={`bulk_presentation_type_${o?.id}`}
                                id="bulk_presentation_type"
                                onChange={() =>
                                  handlePresentationTypeChange(
                                    o?.id,
                                    "Virtual",
                                    o?.author
                                  )
                                }
                                checked={selectedType === "Virtual"}
                              />

                              <div className="text-16-400 color-raisin-black ms-3">
                                Virtual
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <RadioInput
                                name={`bulk_presentation_type_${o?.id}`}
                                id="bulk_presentation_type"
                                onChange={() =>
                                  handlePresentationTypeChange(
                                    o?.id,
                                    "Hybrid",
                                    o?.author
                                  )
                                }
                                checked={selectedType === "Hybrid"}
                              />

                              <div className="text-16-400 color-raisin-black ms-3">
                                Hybrid
                              </div>
                            </div>
                          </div>
                          {errors?.bulk_presentation_type && (
                            <span className="text-13-400 pt-1 d-flex align-items-cemter gap-2">
                              <span style={{ color: "red" }}>
                                {
                                  errors?.bulk_presentation_type[i]
                                    ?.presentation_type
                                }
                              </span>
                            </span>
                          )}
                        </div>
                        <div className="col-lg-4 cmb-24"></div>
                      </React.Fragment>
                    );
                  })}
                </>
              )}

              {type === "1" ? (
                paperData?.co_author_details?.length > 0 && (
                  <div className={`col-lg-6 cmb-24`}>
                    <div className={`${isCoAuthorBlock ? "border" : ""}   `}>
                      <div
                        className="d-flex align-items-center justify-content-between text-14-500-18 color-new-car bg-f4ff pointer cmb-22 cps-24 cpt-12 cpb-12 cpe-24"
                        onClick={() => {
                          setIsCoAuthorBlock(!isCoAuthorBlock);
                        }}
                      >
                        Co-Author Name
                        {isCoAuthorBlock ? (
                          <i className="bi bi-chevron-up color-new-car" />
                        ) : (
                          <i className="bi bi-chevron-down color-new-car" />
                        )}
                      </div>

                      {isCoAuthorBlock &&
                        paperData?.co_author_details?.map((elem, index) => {
                          return (
                            <div className="cps-24 cpe-24 row" key={index}>
                              <div className="col-lg-4 cmb-24">
                                <div className="cmb-5 text-14-400-18 color-1717">
                                  Co Author Name :
                                </div>
                                <div className="text-14-300-24 color-black-olive">
                                  {elem?.name}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )
              ) : (
                <div className={`col-lg-6 cmb-24`}>
                  <div className={`${isCoAuthorBlock ? "border" : ""}   `}>
                    <div
                      className="d-flex align-items-center justify-content-between text-14-500-18 color-new-car bg-f4ff pointer cmb-22 cps-24 cpt-12 cpb-12 cpe-24"
                      onClick={() => {
                        setIsCoAuthorBlock(!isCoAuthorBlock);
                      }}
                    >
                      Co-Author Name
                      {isCoAuthorBlock ? (
                        <i className="bi bi-chevron-up color-new-car" />
                      ) : (
                        <i className="bi bi-chevron-down color-new-car" />
                      )}
                    </div>

                    {isCoAuthorBlock &&
                      (bulkCoAuthors?.length > 0 ? (
                        bulkCoAuthors?.map((elem, index) => {
                          return (
                            <div className="cps-24 cpe-24 row" key={index}>
                              <div className="col-lg-4 cmb-24">
                                <div className="cmb-5 text-14-400-18 color-1717">
                                  Co Author Name {index + 1}:
                                </div>
                                <div className="text-14-300-24 color-black-olive">
                                  {elem?.name}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="cpb-20 text-center">No data</div>
                      ))}
                  </div>
                </div>
              )}
              <div
                className={
                  paperData?.co_author_details?.length > 0
                    ? "col-lg-6"
                    : "col-lg-12"
                }
              ></div>
              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Reference No :"
                  id="reference_no"
                  placeholder="Enter reference no"
                  value={reference_no}
                  error={errors?.reference_no}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-8"></div>

              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Total Amount :"
                  id="amount"
                  placeholder="Enter amount"
                  value={amount}
                  error={errors?.amount}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Dropdown
                  label={"Payment Category :"}
                  id="payment_category"
                  placeholder="select"
                  options={[
                    { name: "Journal Publication", value: "1" },
                    { name: "Submitted Abstract", value: "2" },
                  ]}
                  optionValue={"name"}
                  optionKey={"value"}
                  value={payment_category}
                  error={errors?.payment_category}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Dropdown
                  label={"Payment Mode :"}
                  id="payment_mode"
                  placeholder="select"
                  options={[{ name: "UPI" }, { name: "NEFT" }]}
                  value={payment_mode}
                  optionValue={"name"}
                  optionKey={"name"}
                  error={errors?.payment_mode}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-4 cmb-24">
                <FileUpload
                  label={"Payment Proof :"}
                  id="payment_proof"
                  error={errors?.payment_proof}
                  fileText={getFilenameFromUrl(
                    payment_proof_name || "Payment Proof"
                  )}
                  onChange={(e) => {
                    const fileName = e?.target?.fileName;
                    const file = e?.target?.value;
                    setFieldValue("payment_proof_name", fileName);
                    setFieldValue("payment_proof", file);
                  }}
                />
              </div>

              <div className="col-lg-8 cmb-24">
                <TextInput
                  label="Remarks :"
                  id="payment_remarks"
                  value={payment_remarks}
                  placeholder="Enter remarks"
                  onChange={handleChange}
                />
              </div>

              <div className="text-16-400-20 color-1717 cmb-24">
                Billing Address :
              </div>
              <div className="col-lg-4 cmb-24">
                <Location
                  type="country"
                  data={{
                    label: "Country :",
                    id: "country",
                    placeholder: "select",
                    value: country,
                    error: errors?.country,
                    onChange: (e) => {
                      setFieldValue("country_name", e?.target?.data?.country);
                      handleChange(e);
                    },
                  }}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Location
                  type="state"
                  data={{
                    label: "State :",
                    id: "state",
                    placeholder: "select",
                    value: state,
                    countryId: country,
                    disabled: !country,
                    error: errors?.state,
                    onChange: (e) => {
                      setFieldValue("state_name", e?.target?.data?.state);
                      handleChange(e);
                    },
                  }}
                />
              </div>

              <div className="col-lg-4 cmb-22">
                <CreatableCityDropDown
                  label="City"
                  id="city"
                  disabled={!state}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={city}
                  error={errors?.city}
                  countryId={values?.country_name}
                  stateId={values?.state_name}
                  isClearable
                />
              </div>
              {+values?.city === 586 && (
                <div className="col-lg-4 cmb-22">
                  <TextInput
                    placeholder="Add Other City"
                    id="other_city"
                    value={values.other_city}
                    error={errors.other_city}
                    onChange={handleChange}
                  />
                </div>
              )}

              <div className="col-lg-8 cmb-24">
                <TextInput
                  label="Address :"
                  id="address"
                  value={address}
                  placeholder="Enter address"
                  onChange={handleChange}
                  error={errors?.address}
                />
              </div>

              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Zip Code :"
                  id="zip_code"
                  value={zip_code}
                  placeholder="Enter Zip code"
                  onChange={handleChange}
                  error={errors?.zip_code}
                />
              </div>

              <div className="d-flex">
                <Button
                  text={"Submit"}
                  btnStyle={"primary-dark"}
                  className={"h-35 cps-20 cpe-20"}
                  btnLoading={btnLoading}
                  disabled={isEqual(initialValues, values)}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default SingleForm;
