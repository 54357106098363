import Card from "components/Layout/Card";
import React, { useState } from "react";
import { icons } from "utils/constants";
import { Formik } from "formik";
import * as Yup from "yup";
import PasswordInput from "components/form/PasswordInput";
import Button from "components/form/Button";
import HeaderLayout from "components/Layout/HeaderLayout";
import { useDispatch, useSelector } from "react-redux";
import "./ChangePasswordTempUsers.scss";
import { setTempUserData, updatePasswordTempUser } from "store/slices";
import { objectToFormData } from "utils/helpers";
import { useNavigate } from "react-router-dom";

const ChangePasswordTempUsers = () => {
  const { tempUserData } = useSelector((state) => ({
    tempUserData: state.auth.tempUserData,
  }));
  const dispatch = useDispatch();
  const nevigate = useNavigate();

  console.log("tempUserData", tempUserData);

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .required("New password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
        "Password was not strong."
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required.")
      .oneOf([Yup.ref("new_password"), null], "Password not matched."),
  });
  const handelChangePassword = async (values) => {
    setIsLoading(true);
    const payload = { ...values, ...tempUserData };
    const response = await dispatch(
      updatePasswordTempUser(objectToFormData(payload))
    );
    console.log("response", response);
    if (response?.status === 200) {
      nevigate("/professional/dashboard");
      dispatch(setTempUserData(""))
    }
    setIsLoading(false);
  };
  return (
    <HeaderLayout>
      <div id="change-password-temp-user-container">
        <Card className="card-padding ps-5 pe-5 pt-5 pb-5">
          <div className="d-flex center-flex position-relative mb-5">
            <span
              className="d-flex position-absolute start-0"
              onClick={() => {}}
            >
              <img
                src={icons.leftArrow}
                alt="back"
                className="h-21 me-3 pointer"
              />
            </span>
            <div className="text-28-500 color-black-olive">New Password</div>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handelChangePassword(values);
            }}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="cmb-30 pt-3">
                    <PasswordInput
                      labelClass="mb-2"
                      id="new_password"
                      label="New Password"
                      placeholder="Enter New Password"
                      onChange={handleChange}
                      value={values.new_password}
                      error={errors.new_password}
                      isShowSuggetion
                    />
                  </div>
                  <div>
                    <PasswordInput
                      labelClass="mb-2"
                      id="confirm_password"
                      label="Confirm New Password"
                      placeholder="Confirm New Password"
                      onChange={handleChange}
                      value={values.confirm_password}
                      error={errors.confirm_password}
                    />
                  </div>

                  <div className="mt-5">
                    <Button
                      isRounded
                      text="Submit"
                      btnStyle="primary-dark"
                      onClick={handleSubmit}
                      disabled={isLoading}
                      btnLoading={isLoading}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      </div>
    </HeaderLayout>
  );
};

export default ChangePasswordTempUsers;
