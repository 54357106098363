import React, { useEffect, useState } from "react";
import Label from "components/form/Label";
import Select from "react-select";
import { includes } from "lodash";
import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import Profile from "components/Layout/Profile";
import "./SegmentAllocatedUserDropdown.scss";

const SegmentAllocatedUserDropdown = ({
  label,
  isRequired,
  labelClass,
  options,
  placeholder,
  optionKey,
  optionValue,
  value,
  isLoading,
  disabled,
  name,
  id,
  isPreviousUsers,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const optId = optionKey || "id";
  const optVal = optionValue || "label";
  let fillValue = null;
  if (value) {
    const idArray = value?.split(",");
    fillValue = options?.filter((o) => includes(idArray, o[optId] + ""));
  }

  const handleCheckboxChange = (data) => {
    const isSelected = selectedOptions?.some((o) => o[optId] === data[optId]);

    const updatedOptions = isSelected
      ? selectedOptions?.filter((o) => o[optId] !== data[optId])
      : [...selectedOptions, data];

    setSelectedOptions(updatedOptions);
  };

  const handleAllocate = () => {
    onChange(selectedOptions);
  };

  useEffect(() => {
    setSelectedOptions((prev) =>
      prev?.filter((option) =>
        isPreviousUsers?.some((user) => user[optId] === option[optId])
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousUsers]);

  const CustomOption = (props) => {
    const { id, name, email_id, member_id, phone_no, profile_image } =
      props?.data || {};
    const isChecked = selectedOptions?.some(
      (o) => o[optId] === props?.data[optId]
    );
    const isDisabled = isPreviousUsers?.some((o) => o?.id === id);
    const handleClick = () => {
      handleCheckboxChange(props?.data);
      props?.selectOption(props?.data);
    };

    return (
      <div
        className={`gap-3 custom-option ${isDisabled ? "disabled" : ""}`}
        onClick={handleClick}
        style={isDisabled ? { pointerEvents: "none", opacity: 0.5 } : {}}
      >
        <div className="me-2 ">
          <CheckBox
            type="PRIMARY-ACTIVE"
            onClick={() => {}}
            isChecked={isChecked}
            readOnly
          />
        </div>
        <div style={{ width: "48px", height: "48px" }}>
          <Profile
            url={profile_image}
            size="s-48"
            isRounded
            text={name}
            isS3UserURL
          />
        </div>
        <div className=" flex-grow-1 pointer row">
          <div className={`"cmb-12"} col-lg-4`}>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {name}
            </div>
            <div className="text-12-400-15 color-black-olive">{member_id}</div>
          </div>
          <div className={`"cmb-12"} col-lg-5`}>
            <div className="text-14-300-17 color-black-olive cmb-12 w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-envelope" />
              {email_id}
            </div>
            <div className="text-14-300-17 color-black-olive w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-telephone" /> {phone_no}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="segment-users-dropdown-container">
        <div className="w-100">
          {label && (
            <Label label={label} required={isRequired} className={labelClass} />
          )}
          <Select
            isMulti
            placeholder={placeholder}
            options={options}
            name={name}
            getOptionLabel={(option) => option[optVal]}
            getOptionValue={(option) => option[optId]}
            isDisabled={disabled}
            isLoading={isLoading}
            value={fillValue}
            components={{ Option: CustomOption }}
            className="basic-multi-select"
            classNamePrefix="select"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
          />
        </div>
      </div>
      <div className="d-flex mt-4">
        <Button
          text="Allocate User"
          btnStyle="primary-dark"
          className="h-35 cps-20 cpe-20"
          onClick={handleAllocate}
          disabled={selectedOptions?.length === 0}
        />
      </div>
    </>
  );
};

export default SegmentAllocatedUserDropdown;
