import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import Button from "components/form/Button";
import ExportButton from "components/Layout/ExportButton";
import { getUserType, objectToFormData } from "utils/helpers";
import SeachInput from "components/form/SeachInput";
import Loader from "components/Layout/Loader";
import AddSegmentPopup from "./AddSegmentPopup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { exportAllSegment, fetchAllSegment } from "store/slices";
import moment from "moment";
import "./SegmentManagement.scss";
import { Pagination } from "react-bootstrap";

const SegmentManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memberType = getUserType();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddPopup, setIsAddPopup] = useState(false);
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState("");
  const [searchPayload, setSearchPayload] = useState({ event_name: "" });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
  });

  const getAllSegmentList = async (obj) => {
    const response = await dispatch(fetchAllSegment(objectToFormData(obj)));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.segments) {
      resList = response?.data?.segments;
      resResultCount = response?.data?.result_count;
    }
    setList(resList);
    setFilterData({
      ...obj,
      total: resResultCount,
    });
    setIsLoading(false);
  };

  const handleSearch = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      const updatedSearchPayload = { ...searchPayload, event_name: value };
      setSearchPayload(updatedSearchPayload);
      const updatedFilterData = {
        ...filterData,
        offset: 0,
        ...updatedSearchPayload,
      };
      setIsLoading(true);
      setFilterData(updatedFilterData);
      getAllSegmentList(updatedFilterData);
    }, 800);
    setTimer(time);
  };

  useEffect(() => {
    getAllSegmentList({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activePage = filterData?.offset / filterData?.limit + 1;
  const totalPage = Math.ceil(filterData?.total / filterData?.limit);

  const handlePagination = (offset) => {
    setIsLoading(true);
    const newData = { ...filterData, ...searchPayload, offset: offset };
    getAllSegmentList(newData);
  };

  return (
    <>
      {isAddPopup && (
        <AddSegmentPopup
          type="Add"
          onHide={() => {
            setIsAddPopup(false);
          }}
          handleSuccess={() => {
            getAllSegmentList({ ...filterData, ...searchPayload });
          }}
        />
      )}
      <div className="segment-management-container">
        <Card className="cpt-34 cpe-20 cps-20 cpb-34">
          <div className="d-flex align-items-center justify-content-between cmb-30">
            <div className="table-title">Segments ({filterData?.total})</div>
            <div className="d-flex align-items-center flex-wrap gap-3">
              <div style={{ width: "220px" }}>
                <SeachInput
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>

              <ExportButton
                exportAPI={exportAllSegment}
                payload={objectToFormData({ ...filterData, export_status: 1 })}
              />

              <Button
                onClick={() => {
                  setIsAddPopup(true);
                }}
                text="+ Add Segment"
                btnStyle="primary-outline"
                className="h-35 text-14-500"
                isSquare
              />
            </div>
          </div>
          {isLoading ? (
            <div className="center-flex cpt-50 cpb-50">
              <Loader size="md" />
            </div>
          ) : list?.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center cpt-200 cpb-200">
              No data found
            </div>
          ) : (
            <>
              <div className="row">
                {list?.map((elem, index) => {
                  return (
                    <div className="col-md-4 cmb-14" key={index}>
                      <div className="cps-14 cpt-14 cpe-14 cpb-17 conference-segment-border h-100 d-flex flex-column justify-content-between">
                        <div
                          className="text-14-500-24 cmb-12 text-dots-conference-segment conference-segment-name"
                          onClick={() => {
                            navigate(`/${memberType}/segment-users/${elem?.id}`);
                          }}
                        >
                          {elem?.event_name}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="text-14-400-24 color-black-olive">
                            Segment : {elem?.segments}
                          </div>
                          <div className="text-14-400-24 color-black-olive">
                            Created :{" "}
                            {elem?.created_at
                              ? moment(elem?.created_at).format("DD-MMM-YYYY")
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {totalPage > 1 && (
                <div className="d-flex align-items-center justify-content-between flex-wrap footer-block">
                  <div className="d-flex gap-4 cmt-30 cpb-30">
                    <Button
                      icon={<i className="bi bi-chevron-left me-1" />}
                      text="Previous"
                      btnStyle={
                        activePage === 1 ? "primary-gray" : "primary-dark"
                      }
                      className="cps-20 cpe-20"
                      onClick={() => {
                        if (activePage !== 1) {
                          handlePagination(
                            filterData?.offset - filterData?.limit
                          );
                        }
                      }}
                      isRounded
                    />
                    <Button
                      rightIcon={<i className="bi bi-chevron-right ms-2" />}
                      text="Next"
                      btnStyle={
                        activePage === totalPage
                          ? "primary-gray"
                          : "primary-dark"
                      }
                      className="cps-40 cpe-30"
                      onClick={() => {
                        if (activePage !== totalPage) {
                          handlePagination(
                            filterData?.offset + filterData?.limit
                          );
                        }
                      }}
                      isRounded
                    />
                  </div>

                  <div className="d-flex align-items-center ">
                    <Pagination className="d-flex align-items-center unset-m">
                      <div className="text-16-400 color-davys-gray me-1">
                        Page
                      </div>
                      <Pagination.Item disabled>{activePage}</Pagination.Item>
                      <div className="text-16-400 color-davys-gray ms-1 me-2">
                        of
                      </div>
                      <Pagination.Item disabled>{totalPage}</Pagination.Item>
                    </Pagination>
                  </div>
                </div>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default SegmentManagement;
