import React, { useEffect, useState } from "react";
import ReviewReportDetails from "../ReviewReportDetails";
import RevisionPaperForm from "../RevisionPaperForm";
import Button from "components/form/Button";
import { icons } from "utils/constants";
import { useParams } from "react-router-dom";

const UserReviewState = ({
  data,
  paperData,
  handleSuccess,
  setIsAddRevisionAbstract,
  scrollToAbstract,
  revisionFormRef
}) => {
  const params = useParams();
  const { paperId } = params;
  const [isOldReport, setIsOldReport] = useState(null);
  const [isReviewRevisions, setIsReviewRevisions] = useState(false);

  const {
    status,
    is_remodify,
  } = data;

  const { submittedPapersStatus, abstract_reviews: oldReports } =
    paperData || {};
  const { revision_status, is_plagiarism_done } = submittedPapersStatus || {};

  const isReview = ["2", "3"].includes(is_remodify);
  const isReviewRevision = is_remodify === "1";
  const isActiveReview = ["1", "2", "3"].includes(is_remodify);

  useEffect(() => {
    if (oldReports?.length >= 0) {
      setIsOldReport(oldReports?.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldReports]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            isActiveReview && status >= 2 ? "active" : "not-active"
          }`}
        >
          {isReview ? <img src={icons?.RightIcon} alt="right" /> : 3}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveReview && status >= 2 ? "color-new-car" : "color-6866"
            }`}
          >
            Review
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        {is_plagiarism_done === "1" || status >= 3 ? (
          <>
            <div className="cpt-14 cpb-20 w-100 ">
              {isReview ? (
                <div className="w-100">
                  {oldReports?.map((oldReport, i) => {
                    return (
                      <div
                        className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                        key={i + 1}
                      >
                        <div
                          className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                          onClick={() => {
                            setIsOldReport(isOldReport === i ? null : i);
                          }}
                        >
                          {i === 0 && <span>Your Review Report</span>}
                          {i > 0 && <span>Revision {i}</span>}
                          <i
                            className={`${
                              isOldReport === i
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        {isOldReport === i && (
                          <>
                            <ReviewReportDetails
                              elem={oldReport}
                              paperData={paperData}
                              isOldReport
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : isReviewRevision ? (
                <>
                  {isReviewRevisions ? (
                    <RevisionPaperForm
                      abstractId={paperId}
                      onHide={() => {
                        setIsReviewRevisions(false);
                      }}
                      handleSuccess={handleSuccess}
                      isReviewState
                    />
                  ) : (
                    <>
                      {oldReports?.map((oldReport, i) => {
                        return (
                          <div
                            className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                            key={i + 1}
                          >
                            <div
                              className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                              onClick={() => {
                                setIsOldReport(isOldReport === i ? null : i);
                              }}
                            >
                              {i === 0 && <span>Your Review Report</span>}
                              {i > 0 && <span>Revision {i}</span>}
                              <i
                                className={`${
                                  isOldReport === i
                                    ? "bi bi-chevron-up"
                                    : "bi bi-chevron-down"
                                }`}
                                style={{ fontSize: "15px" }}
                              />
                            </div>
                            {isOldReport === i && (
                              <>
                                <ReviewReportDetails
                                  elem={oldReport}
                                  paperData={paperData}
                                  isOldReport
                                />

                                {oldReports?.length - 1 === i &&
                                  is_plagiarism_done === "0" &&
                                  revision_status === "2" &&
                                  status === 3 &&
                                  isReviewRevision && (
                                    <div className="text-14-400-17 color-6866 cmb-18 cps-24">
                                      <div className="d-flex">
                                        <Button
                                          text={`+ Add Abstract`}
                                          btnStyle="primary-dark"
                                          className="text-15-500 cps-15 cpe-15 h-30"
                                          onClick={() => {
                                            scrollToAbstract(revisionFormRef);
                                            setIsAddRevisionAbstract(true);
                                            // setIsReviewRevisions(true);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
                  Review is under processing
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="w-100 cpt-20 cpb-20"></div>
        )}
      </div>
    </>
  );
};

export default UserReviewState;
