import Button from "components/form/Button";
import SeachInput from "components/form/SeachInput";
import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import AddSegmentPopup from "../AddSegmentPopup";
import Loader from "components/Layout/Loader";
import "./SegmentUserDetails.scss";
import Profile from "components/Layout/Profile";
import CheckBox from "components/form/CheckBox";
import DeletePopup from "components/Layout/DeletePopup";
import AddUserPopup from "../AddUserPopup";
import { useDispatch } from "react-redux";
import { deleteSegmentUser, singleSegmentUserList } from "store/slices";
import { objectToFormData } from "utils/helpers";
import { useParams } from "react-router-dom";
import { Pagination } from "react-bootstrap";

const SegmentUserDetails = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const [isAddPopup, setIsAddPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState({});
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState("");
  const [searchPayload, setSearchPayload] = useState({ search: "" });

  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 5,
    id: id,
  });
  const [selectAll, setSelectAll] = useState(false);
  const [usersDeleteIds, setUsersDeleteIds] = useState(null);
  const [isUserAddPopup, setIsUserAddPopup] = useState(false);

  const getSingleSegmentUserList = async (obj) => {
    const response = await dispatch(
      singleSegmentUserList(objectToFormData(obj))
    );
    let resResultCount = 0;
    if (response?.data?.user_details) {
      resResultCount = response?.data?.result_count;
      response.data.user_details = response?.data.user_details?.map((user) => ({
        ...user,
        is_checked: 0, // Default value
      }));
    }
    setUserList(response?.data || {});
    setFilterData({ ...obj, total: resResultCount || 0 });
    setIsLoading(false);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll; // Determine the new state of selectAll
    setSelectAll(newSelectAll); // Update the selectAll state
    setUserList((prev) => ({
      ...prev,
      user_details: prev?.user_details?.map((user) => ({
        ...user,
        is_checked: newSelectAll ? 1 : 0, // Update each user's is_checked property
      })),
    }));
    //   setSelectAll(!selectAll);
    //   setUserList((prev) => ({
    //   ...prev,
    //   user_details: prev?.user_details?.map((user) => ({
    //     ...user,
    //     is_checked: !selectAll ? 1 : 0,
    //   })),
    // }));
  };

  const handleSingleCheck = (id) => {
    setUserList((prev) => {
      const updatedUsers = prev?.user_details?.map((user) =>
        user?.id === id
          ? { ...user, is_checked: user?.is_checked ? 0 : 1 }
          : user
      );
      const allSelected = updatedUsers?.every((user) => user?.is_checked === 1);
      setSelectAll(allSelected);
      return {
        ...prev,
        user_details: updatedUsers,
      };
    });
  };

  const handleDelete = async () => {
    const selectedUsersIds = userList?.user_details
      ?.filter((o) => o?.is_checked === 1)
      ?.map((o) => o?.id);
    const idString = selectedUsersIds?.join(",");
    if (idString) {
      setUsersDeleteIds(idString);
    } else {
      setUsersDeleteIds(null);
    }
  };

  const handleSearch = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      const updatedSearchPayload = { ...searchPayload, search: value };
      setSearchPayload(updatedSearchPayload);
      const updatedFilterData = {
        ...filterData,
        offset: 0,
        ...updatedSearchPayload,
      };
      setIsLoading(true);
      setFilterData(updatedFilterData);
      getSingleSegmentUserList(updatedFilterData);
    }, 800);
    setTimer(time);
  };

  useEffect(() => {
    getSingleSegmentUserList({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isDeleteButton = userList?.user_details?.some(
    (o) => o?.is_checked === 1
  );

  const activePage = filterData?.offset / filterData?.limit + 1;
  const totalPage = Math.ceil(filterData?.total / filterData?.limit);

  const handlePagination = (offset) => {
    setIsLoading(true);
    const newData = { ...filterData, ...searchPayload, offset: offset };
    getSingleSegmentUserList(newData);
  };

  return (
    <>
      {isUserAddPopup && (
        <AddUserPopup
          onHide={() => {
            setIsUserAddPopup(false);
          }}
          segmentId={isUserAddPopup}
          handelSuccess={() => {
            getSingleSegmentUserList({ ...filterData, ...searchPayload });
          }}
        />
      )}
      {isAddPopup && (
        <AddSegmentPopup
          type={"Add"}
          onHide={() => {
            setIsAddPopup(false);
          }}
          handleSuccess={() => {
            getSingleSegmentUserList({ ...filterData, ...searchPayload });
          }}
        />
      )}
      {usersDeleteIds && (
        <DeletePopup
          title="Delete Users"
          message="Are you sure want to delete user?"
          id={usersDeleteIds}
          onHide={() => {
            setUsersDeleteIds(null);
          }}
          handelSuccess={() => {
            getSingleSegmentUserList({ ...filterData, ...searchPayload });
            setUsersDeleteIds(null);
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ id: usersDeleteIds });
            const response = await dispatch(deleteSegmentUser(forData));
            return response;
          }}
          isPrimaryPopup
        />
      )}
      <div className="segment-user-detail-container">
        <Card className="cpt-34 cpe-20 cps-20 cpb-34">
          <div className="d-flex align-items-center justify-content-between cmb-30 cpb-23 border-bottom">
            <div className="table-title">
              New Users ({filterData?.total || 0})
            </div>
            <div className="d-flex align-items-center flex-wrap gap-3">
              <div style={{ width: "220px" }}>
                <SeachInput
                  placeholder="Name or Email"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>

              <Button
                onClick={() => {
                  setIsAddPopup(true);
                }}
                text="+ Add Segment"
                btnStyle="primary-outline"
                className="h-35 text-14-500"
                isSquare
              />
            </div>
          </div>

          {isLoading ? (
            <div className="center-flex cpt-50 cpb-50">
              <Loader size="md" />
            </div>
          ) : (
            <div className="row">
              <div className="cmb-14 text-16-400-27 color-black-olive">
                {userList?.event_name}
              </div>

              <div className="cmb-14 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  {userList?.user_details?.length !== 0 && (
                    <div
                      className="all-user-check d-flex align-items-center"
                      onClick={() => {
                        handleSelectAll();
                      }}
                    >
                      <div className="me-2">
                        <CheckBox
                          type="PRIMARY-ACTIVE"
                          onClick={() => {}}
                          isChecked={selectAll}
                        />
                      </div>
                      <div className="text-14-400-18 color-black-olive">
                        {" "}
                        Select All Users
                      </div>
                    </div>
                  )}
                  {isDeleteButton && (
                    <div className="d-flex">
                      <Button
                        text={"Delete Users"}
                        className={"h-35 cps-15 cpe-15"}
                        btnStyle={"light-gray-delete"}
                        onClick={handleDelete}
                      />
                    </div>
                  )}
                </div>
                <Button
                  onClick={() => {
                    setIsUserAddPopup(id);
                  }}
                  text="+ Add User"
                  btnStyle="primary-outline"
                  className="h-35 text-14-500"
                  isSquare
                />
              </div>
              {userList?.user_details?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center cpt-200 cpb-200">
                  No data found
                </div>
              ) : (
                <>
                  {userList?.user_details?.map((elem, index) => {
                    return (
                      <div className="col-md-4 cmb-14" key={index}>
                        <div className="cps-14 cpt-16 cpe-14 cpb-14 segment-user-details-border position-relative d-flex flex-wrap gap-3">
                          <div className="position-absolute right-25 top-25 pointer">
                            <CheckBox
                              type="PRIMARY-ACTIVE"
                              onClick={() => {
                                handleSingleCheck(elem?.id);
                              }}
                              isChecked={!!elem.is_checked}
                            />
                          </div>
                          <div className="">
                            <Profile
                              text={elem?.name}
                              url={elem?.profile_image}
                              size="s-134"
                              isS3UserURL
                            />
                          </div>
                          <div className="width-70">
                            <div className="text-14-400-18 color-48c0 cmb-15">
                              {elem?.name}
                            </div>
                            <div className="text-14-400-18 color-6866 d-flex align-items-center gap-2 cmb-15">
                              <i className="bi bi-envelope" /> {elem?.email_id}
                            </div>
                            <div className="text-14-400-18 color-6866 d-flex align-items-center gap-2">
                              <i className="bi bi-telephone" /> {elem?.phone_no}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {totalPage > 1 && (
                    <div className="d-flex align-items-center justify-content-between flex-wrap footer-block">
                      <div className="d-flex gap-4 cmt-30 cpb-30">
                        <Button
                          icon={<i className="bi bi-chevron-left me-1" />}
                          text="Previous"
                          btnStyle={
                            activePage === 1 ? "primary-gray" : "primary-dark"
                          }
                          className="cps-20 cpe-20"
                          onClick={() => {
                            if (activePage !== 1) {
                              handlePagination(
                                filterData?.offset - filterData?.limit
                              );
                            }
                          }}
                          isRounded
                        />
                        <Button
                          rightIcon={<i className="bi bi-chevron-right ms-2" />}
                          text="Next"
                          btnStyle={
                            activePage === totalPage
                              ? "primary-gray"
                              : "primary-dark"
                          }
                          className="cps-40 cpe-30"
                          onClick={() => {
                            if (activePage !== totalPage) {
                              handlePagination(
                                filterData?.offset + filterData?.limit
                              );
                            }
                          }}
                          isRounded
                        />
                      </div>

                      <div className="d-flex align-items-center ">
                        <Pagination className="d-flex align-items-center unset-m">
                          <div className="text-16-400 color-davys-gray me-1">
                            Page
                          </div>
                          <Pagination.Item disabled>
                            {activePage}
                          </Pagination.Item>
                          <div className="text-16-400 color-davys-gray ms-1 me-2">
                            of
                          </div>
                          <Pagination.Item disabled>
                            {totalPage}
                          </Pagination.Item>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default SegmentUserDetails;
